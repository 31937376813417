import axios from 'axios'

import config from '../../../config'
import { SellerCreateDto } from '../entities/SellerCreateDto'
import { SellerDto } from '../entities/SellerDto'

export const getList = (email?: string, pageIndex = 0, pageSize = 10) => {
    const url = new URL('seller', config.apis.pos)
    if (email) {
        url.searchParams.set('email', email)
    }
    url.searchParams.set('pageIndex', pageIndex.toString())
    url.searchParams.set('pageSize', pageSize.toString())
    return axios.get(url.toString())
}

export const get = (id: string) => {
    const url = new URL(`seller/${id}`, config.apis.pos)
    return axios.get(url.toString())
}

export const create = (seller: SellerCreateDto) => {
    const url = new URL('seller', config.apis.pos)
    return axios.post(url.toString(), seller)
}

export const update = (seller: SellerDto) => {
    const url = new URL(`seller/${seller.sellerId}`, config.apis.pos)
    return axios.put(url.toString(), seller)
}