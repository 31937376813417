/* 
    Copyright 2022-06-23 por Diego Andres Estrada Holding S.A ©
    Detalle: Se agrega a EOS mantenedor de configuraciones internacionales POS
*/
import React, { FC, useEffect } from 'react'
import { makeStyles, createStyles, TextField, InputLabel, FormControlLabel, Switch, FormLabel } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { Config } from './entities/InternacionalizationInfoDto'

interface Props {
    config: Config,
    values: Config,
    onHandleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    }
}

export const ConfigTax: FC<Props> = ({
    config,
    values,
    onHandleChange
}) => {
    const styles = useStyles()

    useEffect(()=>{
        values.internacionalization=config.internacionalization
    },[])

    return (
        <div className={styles.container}>

            <InputLabel id="label-title">Configuracion Tributaria</InputLabel>
            <div className={styles.container1}>
                <FormControlLabel
                    value="start"
                    control={<Switch color="primary" defaultChecked={config.internacionalization.showDecimals} onChange={onHandleChange('internacionalization.showDecimals')} />}
                    label={<FormLabel component="legend">Mostrar Decimales</FormLabel>}
                    labelPlacement="start"
                />
                <TextField variant="outlined" size="small" label="Cantidad Decimales" helperText={'Campo numerico'} defaultValue={config.internacionalization.amountDecimals} onChange={onHandleChange('internacionalization.amountDecimals')} />
            </div>
            <div className={values.internacionalization.flatTaxRate ? styles.container1 : styles.container2}>
                <FormControlLabel
                    value="start"
                    control={<Switch color="primary" defaultChecked={config.internacionalization.flatTaxRate} onChange={onHandleChange('internacionalization.flatTaxRate')}/>}
                    label={<FormLabel component="legend">Tasa de impuesto fijo</FormLabel>}
                    labelPlacement="start"
                />
                {values.internacionalization.flatTaxRate &&
                    <TextField variant="outlined" size="small" label="% tasa de impuesto fijo a la venta" helperText={'Campo numerico'} defaultValue={config.internacionalization.taxRateAmount} onChange={onHandleChange('internacionalization.taxRateAmount')} />
                }
                {!values.internacionalization.flatTaxRate &&
                    <Alert style={{ fontSize: 12 }} severity="warning">Al desactivar la taza de impuesto fijo, el valor debera ser proporcionado por el producto</Alert>
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'grid',
            gridTemplateRows: '0.7fr 1fr 1fr',
            rowGap: '8%',
        },
        container1: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            columnGap: '3%'
        },
        container2: {
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
            columnGap: '3%',
            paddingRight: '3%',
        },
    })
)