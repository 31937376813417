import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { StandardPage } from '../../components/_EOS/StandardPage'
import { State } from '../../components/_EOS/State'
import { ActionDispatchStateTable } from './ActionDispatchStateTable'
import { useApi } from '../../hooks/useApi'
import { usePagination } from '../../hooks/usePagination'
import { useNotification } from '../../hooks/useNotification'
import { SnackBar } from '../../components/Notification/Snackbar'
import { ActionDispatchStateCreate } from './ActionDispatchStateCreate'
import { ActionDispatchStateSearch } from './ActionDispatchStateSearch'
import { ApiResponse } from '../../typings/ApiResponse'
import { ActionDispatchStateDto } from './DTO/ActionDispatchStateDto'
import { AlertDialog } from '../../components/Dialog/AlertDialog'
import { getList, remove } from './api'

interface Props {

}

export const ActionDispatchStateList: FC<Props> = () => {
    const history = useHistory()
    const { path } = useRouteMatch()
    const { state, callApi } = useApi<ApiResponse<ActionDispatchStateDto>>()
    const pagination = usePagination(5)
    const notification = useNotification()
    const [createModalOpen, setCreateModalOpen] = useState(false)

    const [actionDispatchStateName, setActionDispatchStateName] = useState('')
    const [actionDispatchStateCode, setActionDispatchStateCode] = useState('')
    const [currentActionDispatchStateId, setCurrentActionDispatchStateId] = useState(0)
    const [openDialog, setOpenDialog] = useState(false)

    const fetchData = (code?: string, name?: string, page?: number, pageSize?: number) => {
        const pageParameter = page !== undefined ? page : pagination.page
        const pageSizeParameter = pageSize !== undefined ? pageSize : pagination.pageSize
        callApi(getList(code, name, pageParameter, pageSizeParameter))
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleActionButton = useCallback(() => {
        setCreateModalOpen(true)
    }, [])

    const handleEdit = (id: number) => {
        history.push(`${path}/edit/${id}`)
    }

    const handleDelete = (id: number) => {
        setCurrentActionDispatchStateId(id)
        setOpenDialog(true)

    }

    const okDialogAction = () => {
        setOpenDialog(false)
        remove(currentActionDispatchStateId.toString())
            .then(() => {
                fetchData(actionDispatchStateCode, actionDispatchStateName, pagination.page, pagination.pageSize)
                notification.success("El registro se eliminó del sistema")
            })
            .catch(() => {
                notification.error("No se puede eliminar el registro de la acción del estado de despacho en el sistema. Revise las dependecias asociadas")
            })

    }

    const cancelDialogAction = () => {
        setOpenDialog(false)
    }

    const handleModalClose = (success?: boolean) => {
        if (success) {
            fetchData()
            setActionDispatchStateName('')
            setActionDispatchStateCode('')
            notification.success("El registro se ha realizado de manera exitosa")
        }
        setCreateModalOpen(false)
    }

    const handlePaginationChange = (page: number, pageSize: number) => {

        fetchData(actionDispatchStateCode, actionDispatchStateName, page, pageSize)
        pagination.setPagination(page, pageSize)
    }

    const handleSearch = () => {
        fetchData(actionDispatchStateCode, actionDispatchStateName, 0, 5)
        pagination.reset()
    }

    const handleReset = () => {
        fetchData()
        setActionDispatchStateName('')
        setActionDispatchStateCode('')
    }


    return (
        <Fragment>
            <StandardPage pageTitle='Administrar Acciones de Estados Homologados' buttonTitle='Crear' buttonAction={handleActionButton}>
                <ActionDispatchStateSearch
                    actionDispatchStateCode={actionDispatchStateCode}
                    onActionDispatchStateCodeChange={setActionDispatchStateCode}
                    actionDispatchStateName={actionDispatchStateName}
                    onActionDispatchStateNameChange={setActionDispatchStateName}
                    onSearch={handleSearch}
                    onReset={handleReset} />
                <State state={state} loadingMessage='Cargando Acciones de Estados Homologados'>
                    <ActionDispatchStateTable
                        data={state.data?.items}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        pagination={{
                            totalItem: state.data?.totalItems || 0,
                            page: pagination.page,
                            pageSize: pagination.pageSize,
                        }}
                        onPaginationChange={handlePaginationChange}
                    />
                </State>
            </StandardPage>
            <ActionDispatchStateCreate open={createModalOpen} onClose={handleModalClose} />
            <AlertDialog title='MCIM' dialogText='Está seguro de eliminar la acción del estado de despacho del sistema?' dialogOpen={openDialog} onOkAction={okDialogAction} onCancelAction={cancelDialogAction}></AlertDialog>
            <SnackBar
                open={notification.snackBarOpen}
                setOpen={notification.handleSnackbar}
                message={notification.snackBarMessage}
                status={notification.snackBarStatus}
            ></SnackBar>

        </Fragment>
    )
}