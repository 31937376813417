import React from 'react'
import { Snackbar as MUISnackbar, } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
//import {Alert, AlertTitle} from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  error: {
    padding: '20px',
    backgroundColor: '#f44336',
    color: 'white'
  },
  success: {
    padding: '20px',
    backgroundColor: '#4caf50',
    color: 'black'
  },
  warning: {
    padding: '20px',
    backgroundColor: '#4caf50',
    color: 'black'
  }
}))

interface SnackbarProps {
  open: boolean
  setOpen: any
  message: string
  status: string
}

export const SnackBar: React.FC<SnackbarProps> = ({
  open,
  setOpen,
  message,
  status
}) => {
  const classes = useStyles()

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <MUISnackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert message={message} status={status} />
      </MUISnackbar>
    </div>
  )
}



interface AlertProps {
    message: string
    status: string
  }
  
  const Alert: React.FC<AlertProps> = ({ message, status }) => {
    const classes = useStyles()
    const statusClass = classes[status]
    return (
      <div
        className={`${statusClass === undefined ? classes.error : statusClass}`}
      >
        <strong>{message}</strong>
      </div>
    )
  }
  
