import React, { FC } from 'react'
import { useFormik } from 'formik'

import { Modal } from '../../components/_EOS/Modal'
import { useApi } from '../../hooks/useApi'
import { create } from './api'

import { PosCreateDto } from './entities/PosCreateDto'
import { CreateForm } from './forms/CreateForm'
import { posSchema } from './forms/schema'
import { Status } from '../../hooks/useFetchState'

interface Props {
    open: boolean
    onClose: (success?: boolean) => void
}

export const PosCreate: FC<Props> = ({
    open,
    onClose,
}) => {
    const { state, callApi } = useApi()

    const {
        values,
        errors,
        handleChange,
        resetForm,
        submitForm
    } = useFormik<PosCreateDto>({
        initialValues: {
            name: '',
            posCode: '',
            storeCode: ''
        },
        onSubmit: values => {
            sendData(values)
        },
        validationSchema: posSchema,
        validateOnChange: true
    })

    const sendData = (pos: PosCreateDto) => {
        callApi(create(pos))
            .then(() => {
                resetForm()
                onClose(true)
            })
    }

    const handleSuccessButton = () => {
        submitForm()
    }

    return(
        <Modal
        open={open}
        title='Crear Pos'
        loading={state.status === Status.Loading}
        error={state.status === Status.Error ? state.error : undefined}
        cancelButton={{
            title: 'Cancelar',
            action: () => {
                resetForm()
                onClose()
            }
        }}
        successButton={{
            title: 'Guardar',
            action: handleSuccessButton,
        }}>

        <CreateForm values={values} errors={errors} onHandleChange={handleChange} />

    </Modal>
    )
}