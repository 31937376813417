import React, { FC } from 'react'
import { useFormik } from 'formik'
import { LoadingButton } from '../../../components/_EOS/Button/LoadingButton'

import { Status } from '../../../hooks/useFetchState'
import { CourierDto } from '../DTO/CourierDto'
import { courierSchema } from './Schema'
import { useApi } from '../../../hooks/useApi'
import { update } from '../api'
import { useNotification } from '../../../hooks/useNotification'

import { Container, makeStyles, createStyles } from '@material-ui/core'
import { CreateForm } from './CreateForm'
import { useHistory } from 'react-router-dom'
import { SnackBar } from '../../../components/Notification/Snackbar'

interface Props {
    courier: CourierDto
}

export const EditForm: FC<Props> = ({
    courier
}) => {
    const styles = useStyles()
    const history = useHistory()
    const { state, callApi } = useApi()
    const notification = useNotification()

    const {
        values,
        errors,
        handleChange,
        submitForm
    } = useFormik<CourierDto>({
        initialValues: courier,
        onSubmit: (values: CourierDto) => {
            sendData(values)
        },
        validationSchema: courierSchema,
        validateOnChange: true
    })

    const sendData = (courier: CourierDto) => {
        callApi(update(courier))
            .then(() => {
                notification.success("La actualización se realizó con éxito.")                
                history.goBack()                
            })
    }

    const handleSubmitForm = () => {
        submitForm()
    }

    return (
        <Container className={styles.editContainer}>
            <CreateForm values={values} errors={errors} handleChange={handleChange} />
            <div className={styles.buttonContainer}>
                <LoadingButton loading={state.status === Status.Loading} onClick={handleSubmitForm}>
                    Editar
                </LoadingButton>
            </div>
            <SnackBar
                open={notification.snackBarOpen}
                setOpen={notification.handleSnackbar}
                message={notification.snackBarMessage}
                status={notification.snackBarStatus}
            ></SnackBar>

        </Container>
    )
}

const useStyles = makeStyles(
    createStyles({
        editContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: 10,
        },
        editInput: {
            marginBottom: 30,
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
        }
    })
)