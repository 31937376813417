import React, { FC } from 'react'
import { makeStyles, createStyles, Button, TextField } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'

interface Props {
    posName: string
    onChangePosName: (email: string) => void
    onSearch: () => void
}

export const PosSearch: FC<Props> = ({
    posName,
    onChangePosName,
    onSearch
}) => {
    const styles = useStyles()
    return (
        <div className={styles.container}>
            <div>
                <TextField label="Ingrese nombre pos" value={posName} onChange={(event) => onChangePosName(event.target.value as string)}/>
            </div>
            <Button variant='text' color='primary' endIcon={<SearchIcon/>} onClick={onSearch}>Buscar</Button>
        </div>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        }
    })
)