import React, { FC, useContext, useEffect, useMemo } from 'react'
import { getNeighborhoods, getStates } from '../../api/location'

import { useApi, ReturnType } from '../../hooks/useApi'
import { ApiResponse } from '../../typings/ApiResponse'
import { NeighborhoodDto } from '../../typings/location/NeighborhoodDto'
import { StateDto } from '../../typings/location/StateDto'

interface LocationContext {
    stateApi: ReturnType<ApiResponse<StateDto>>
    neighborhoodApi: ReturnType<ApiResponse<NeighborhoodDto>>
}

interface Props {
    selectedStateName: string
    selectedNeighborhoodName: string
}

const LocationContext = React.createContext<LocationContext | undefined>(undefined)

const getCode = (data: any[], name: string) => data?.find(item => item.name.toLowerCase() === name.toLowerCase())?.code

export const LocationProvider: FC<Props> = ({
    selectedStateName,
    children
}) => {
    const stateApi = useApi<ApiResponse<StateDto>>()
    const neighborhoodApi = useApi<ApiResponse<NeighborhoodDto>>()

    useEffect(() => {
        stateApi.callApi(getStates())
        .then((data) => {
            if(selectedStateName) {
                neighborhoodApi.callApi(getNeighborhoods(getCode(data.items, selectedStateName)))
            }
        })
    }, [])

    useEffect(() => {
        if(!stateApi.state.data?.items) {
            return
        }

        const stateCode = getCode(stateApi.state.data?.items, selectedStateName)
    
        neighborhoodApi.callApi(getNeighborhoods(stateCode))
    }, [selectedStateName])

    const data = useMemo(() => ({
        stateApi,
        neighborhoodApi,
    }), [stateApi, neighborhoodApi])

    return (
        <LocationContext.Provider value={data}>
            { children }
        </LocationContext.Provider>
    )
}

export const useLocation = () => {
    const context = useContext(LocationContext)

    if (context === undefined) {
        throw new Error('useLocation shoul be inside LocationProvider')
    }

    return context
}
