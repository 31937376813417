import * as yup from 'yup'


const dispatchStateYup = yup    
    .string()
    .required('Este campo es requerido')

const dispatchStateTypeYup = yup    
    .string()
    .required('Este campo es requerido')

const couierStateYup = yup
    .string()
    .required('Este campo es requerido')


const couierStateCodeYup = yup    
    .string()
    .required('Este campo es requerido')

const activeYup = yup    
    .string()
    .required('Este campo es requerido')

export const courierDispatchStateSchema = yup.object().shape({
    dispatchStateId: dispatchStateYup, 
    dispatchStateTypeId: dispatchStateTypeYup,
    couierStateCode: couierStateCodeYup, 
    couierState: couierStateYup,
    active: activeYup,
})

