import React, { FC, Fragment, useEffect, useState, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { StandardPage } from '../../components/_EOS/StandardPage'
import { State } from '../../components/_EOS/State'
import { PosTable } from './PosTable'
import { useApi } from '../../hooks/useApi'
import { usePagination } from '../../hooks/usePagination'
import { ApiResponse } from '../../typings/ApiResponse'
import { PosInfoListDto } from './entities/PosInfoListDto'
import { getList } from './api'
import { PosCreate } from './PosCreate'
import { PosSearch } from './PosSearch'

interface Props {

}

export const PosList: FC<Props> = () => {
    const history = useHistory()
    const { path } = useRouteMatch()
    const { state, callApi } = useApi<ApiResponse<PosInfoListDto>>()
    const pagination = usePagination(5)
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [posName, setPosName] = useState('')
    
    const fetchData = (name?: string, page?: number, pageSize?: number) => {
        const pageParameter = page !== undefined ? page : pagination.page
        const pageSizeParameter = pageSize !== undefined ? pageSize : pagination.pageSize
        callApi(getList(name, pageParameter, pageSizeParameter))
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleActionButton = useCallback(() => {
        setCreateModalOpen(true)
    }, [])

    const handleEdit = (id: string) => {
        history.push(`${path}/edit/${id}`)
    }

    const handleModalClose = (success?: boolean) => {
        if (success) {
            fetchData()
            pagination.reset()
            setPosName('')
        }
        setCreateModalOpen(false)
    }

    const handlePaginationChange = (page: number, pageSize: number) => {
        fetchData(posName, page, pageSize)
        pagination.setPagination(page, pageSize)
    }

    const handleSearch = () => {
        fetchData(posName, 0, pagination.pageSize)
        pagination.reset()
    }

    return (
        <Fragment>
            <StandardPage pageTitle='Pos' buttonTitle='Crear' buttonAction={handleActionButton}>
                <PosSearch posName={posName} onChangePosName={setPosName} onSearch={handleSearch}/>
                <State state={state} loadingMessage='Cargando Pos'>
                    <PosTable 
                        data={state.data?.items}
                        onEdit={handleEdit}
                        pagination={{
                            totalItem: state.data?.totalItems || 0,
                            page: pagination.page,
                            pageSize: pagination.pageSize
                        }}
                        onPaginationChange={handlePaginationChange}
                    />
                </State>
            </StandardPage>
            <PosCreate open={createModalOpen} onClose={handleModalClose} />
        </Fragment>
    )
}