import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { StandardPage } from '../../components/_EOS/StandardPage'
import { State } from '../../components/_EOS/State'
import { useApi } from '../../hooks/useApi'
import { ActionDispatchStateDto } from './DTO/ActionDispatchStateDto'
import { get } from './api'
import { EditForm } from './forms/EditForm'



interface Props {

}

export const ActionDispatchStateEdit: FC<Props> = () => {
    const { id } = useParams<{ id: string }>()
    const actionDispatchStateApi = useApi<ActionDispatchStateDto>()
  
    useEffect(() => {
        actionDispatchStateApi.callApi(get(id))
    }, [id])

    useEffect(() => {
       
    }, [actionDispatchStateApi.state])

    return (
        <StandardPage pageTitle='Editar Acción' backAction={true}>
            <State state={actionDispatchStateApi.state} loadingMessage='Cargando información de la acción de estado de despacho'>
                {
                    actionDispatchStateApi.state.data &&
                        <EditForm actionDispatchState={actionDispatchStateApi.state.data} />
                }
            </State>
         
        </StandardPage>
    )
}