import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }
    })
)
