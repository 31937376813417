import axios from 'axios'

import config from '../../../config'
import { CourierParameterCreateDto } from '../DTO/CourierParameterCreateDto'
import { CourierParameterDto } from '../DTO/CourierParameterDto'


export const getList = (courierId: number, pageIndex = 0, pageSize = 10) => {
    const url = new URL('CourierParameter', config.apis.mcim)
        
    url.searchParams.set('pageIndex', pageIndex.toString())
    url.searchParams.set('pageSize', pageSize.toString())
    url.searchParams.set('courierId', courierId.toString())
    return axios.get(url.toString())
}

export const getParameterList = () => {
    const url = new URL('CourierParameter/GetParameterType', config.apis.mcim)    
    return axios.get(url.toString())
}

export const get = (id: string) => {
    const url = new URL(`CourierParameter/${id}`, config.apis.mcim)
    return axios.get(url.toString())
}

export const create = (courier: CourierParameterCreateDto) => {
    const url = new URL('CourierParameter', config.apis.mcim)
    return axios.post(url.toString(), courier)
}

export const update = (courier: CourierParameterDto) => {
    const url = new URL(`CourierParameter/${courier.courierId}`, config.apis.mcim)
    return axios.put(url.toString(), courier)
}

export const remove = (id: string) => {
    const url = new URL(`CourierParameter/${id}`, config.apis.mcim)
    return axios.delete(url.toString())
}

