import React, { FC, useState, useEffect } from 'react'
import {
    Typography,
    makeStyles,
    createStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
    TableContainer
} from '@material-ui/core'

import { LoadingButton } from '../../../components/_EOS/Button/LoadingButton'
import { Status } from '../../../hooks/useFetchState'
import { useApi } from '../../../hooks/useApi'

import { ActionDispatchStateDto } from '../../ActionDispatchState/DTO/ActionDispatchStateDto'
import { DispatchStateDto } from '../entities/DispatchStateDto'
import { useNotification } from '../../../hooks/useNotification'
import { updateActions } from '../api'
import { SnackBar } from '../../../components/Notification/Snackbar'

interface Props {
    data?: ActionDispatchStateDto[]
    dispatchState: DispatchStateDto
}

//TODO: Modularizar quizás en más componentes para separar estado

export const DispatchStateActions: FC<Props> = ({
    data = [],
    dispatchState
}) => {

    const styles = useStyles()
    const [checkedActions, setCheckedActions] = useState<any[]>([])
    const { state, callApi } = useApi()
    const notification = useNotification()

    useEffect(() => {
        dispatchState.actions.map(action => {
            setCheckedActions(oldArray => [...oldArray, action.actionId]);
        })
    }, [])

    const handleCheckChange = (id: number) => { 
        checkedActions.includes(id) 
            ? setCheckedActions(checkedActions.filter(actionId => actionId !== id ))
            : setCheckedActions(oldArray => [...oldArray, id]);
    }

    const handleSubmitActions = () => {
        callApi(updateActions(dispatchState.dispatchStateId, checkedActions))
        .then(() => {
            notification.success("Acciones asocidas exitosamente")
        })
    }

    return (
        <div>
            <Typography variant='h5' component='h2'>Acciones</Typography>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>Id</TableCell>
                            <TableCell align='center'>Nombre</TableCell>
                            <TableCell align='center'>Descripción</TableCell>
                            <TableCell align='center'>Asociar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.filter(a => a.active).map(item => {
                                return (
                                    <TableRow key={item.actionId}>
                                        <TableCell align='center'>{item.actionId}</TableCell>
                                        <TableCell align='center'>{item.actionName}</TableCell>
                                        <TableCell align='center'>{item.description}</TableCell>
                                        <TableCell align='center'>
                                            <div className={styles.action}>
                                                <Checkbox 
                                                    checked={checkedActions.includes(item.actionId)}
                                                    onChange={() => handleCheckChange(item.actionId)}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={styles.buttonContainer}>
                <LoadingButton 
                    loading={state.status === Status.Loading} 
                    onClick={() => handleSubmitActions()}
                >
                    Asociar acciones
                </LoadingButton>
            </div>
            <SnackBar
                open={notification.snackBarOpen}
                setOpen={notification.handleSnackbar}
                message={notification.snackBarMessage}
                status={notification.snackBarStatus}
            ></SnackBar>     
        </div>
    )
}

const useStyles = makeStyles(
    createStyles({
        action: {
            display: 'inline-block',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
        },
        buttonContainer: {
            display: 'flex',
            marginTop: 20,
            alignItems: 'center',
            justifyContent: 'right',
        }
    })
)

