import React, { FC, useEffect } from 'react'
import { FormikErrors } from 'formik'
import { PosCreateDto } from '../entities/PosCreateDto'
import { StoreDto } from '../../stores/entities/StoreDto'

import { useApi } from '../../../hooks/useApi'
import { getList } from '../../stores/api'
import { ApiResponse } from '../../../typings/ApiResponse'

import { 
    createStyles, 
    makeStyles, 
    TextField, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem, 
    FormHelperText 
} from '@material-ui/core'
interface Props {
    values: PosCreateDto,
    errors: FormikErrors<{
        name: '',
        posCode: '',
        storeCode: ''
    }>
    onHandleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    }
}

export const CreateForm: FC<Props> = ({
    values,
    errors,
    onHandleChange
}) => {
    const styles = useStyles()

    const storeApi = useApi<ApiResponse<StoreDto>>()

    useEffect(() => {
        storeApi.callApi(getList(undefined, undefined, true, 0, 999))
    }, [])

    return (
        <div className={styles.container}>
            <TextField
                className={styles.input}
                label="Nombre"
                inputProps={{ maxLength: 50 }}
                value={values.name}
                onChange={onHandleChange('name')}
                error={!!errors.name}
                helperText={errors.name}
            />
            <TextField
                className={styles.input}
                label="Código"
                inputProps={{ maxLength: 50 }}
                value={values.posCode}
                onChange={onHandleChange('posCode')}
                error={!!errors.posCode}
                helperText={errors.posCode}
            />
            <FormControl fullWidth error={!!errors.storeCode}>
                <InputLabel id='labelCodeStore'>Tienda</InputLabel>
                <Select
                    labelId='labelCodeStore'
                    value={values.storeCode}
                    onChange={onHandleChange('storeCode')}
                >
                    {
                        storeApi.state.data?.items.map(item => {
                            return (
                                <MenuItem key={item.storeId} value={item.code}>{item.name}</MenuItem>
                            )
                        })
                    }
                </Select>
                {
                    !!errors.storeCode && <FormHelperText>{errors.storeCode}</FormHelperText>
                }
            </FormControl>
        </div>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        input: {
            marginBottom: 20,
        }
    })
)
