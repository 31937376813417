import React, { FC } from 'react'
import { useFormik } from 'formik'

import { Modal } from '../../../components/_EOS/Modal'
import { PaymentMethodData } from '../entities/PaymentMethodData'
import { useApi } from '../../../hooks/useApi'
import { createPaymentMethodPos, updatePaymentMethodPos } from '../api'

import { PosOneForm, initialValues as posOneInitialValues } from './forms/PosOneForm'
import { MercadoPagoForm, initialValues as mpInitialValues } from './forms/MercadoPagoForm'
import { FPayForm, initialValues as fpInitialValues } from './forms/FPayForm'
import { Status } from '../../../hooks/useFetchState'

interface Props {
    open: boolean
    onClose: (success?: boolean) => void
    PaymentMethodPosData?: PaymentMethodData
    posCode: string
}

const mapping = {
    'PO': {
        form: PosOneForm,
        initialValues: posOneInitialValues,
    },
    'MP': {
        form: MercadoPagoForm,
        initialValues: mpInitialValues,
    },
    'ML': {
        form: MercadoPagoForm,
        initialValues: mpInitialValues,
    },
    'FP': {
        form: FPayForm,
        initialValues: fpInitialValues,
    }
}

export const PaymentMethodPosConfiguration: FC<Props> = ({
    open,
    onClose,
    PaymentMethodPosData,
    posCode
}) => {
    const PaymentMethodPosApi = useApi()

    const paymentMethodCode = PaymentMethodPosData?.code || ''

    const selectedPaymentMethod = mapping[paymentMethodCode]

    const initialValues = selectedPaymentMethod && PaymentMethodPosData?.paymentMethod 
                            ? {
                                active: PaymentMethodPosData.paymentMethod?.active,
                                ...PaymentMethodPosData.paymentMethod?.configuration
                            }
                            : selectedPaymentMethod.initialValues

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {

            // Transforma los datos de values al objeto que será enviado
            // Deja todos los elementos de values distintos a "active" dentro del objeto configuration
            // de esta forma la api recibe los datos de la forma correcta.
            const data = {
                active: values.active,
                configuration: {
                    ...Object.entries(values).map((entry) => {
                        if(entry[0] === 'active') return null
                        return { [entry[0]]: entry[1] }
                    }).reduce((prev: any, actual: any) => {
                        return {
                            ...prev,
                            ...actual
                        }
                    })
                }
            }

            PaymentMethodPosApi.callApi(
                !PaymentMethodPosData?.paymentMethod
                ? createPaymentMethodPos({ ...data, paymentMethodCode, posCode })
                : updatePaymentMethodPos(posCode, paymentMethodCode, data)
            ).then(() => {
                onClose(true)
            })
        }
    })

    const handleSuccessButton = () => {
        formik.submitForm()
    }

    return (
        <Modal
            open={open}
            title={`Configurar ${PaymentMethodPosData?.name || ''}`}
            loading={PaymentMethodPosApi.state.status === Status.Loading}
            error={PaymentMethodPosApi.state.status === Status.Error ? PaymentMethodPosApi.state.error : undefined}
            cancelButton={{
                title: 'Cancelar',
                action: () => onClose()
            }}
            successButton={{
                title: 'Guardar',
                action: handleSuccessButton
            }}>

            {
                <selectedPaymentMethod.form {...formik}/>
            }

        </Modal>
    )    
}
