import React, { FC } from 'react'
import { useFormik } from 'formik'
import { LoadingButton } from '../../../components/_EOS/Button/LoadingButton'

import { Status } from '../../../hooks/useFetchState'
import { StoreDto } from '../entities/StoreDto'
import { storeSchema } from './schema'
import { useApi } from '../../../hooks/useApi'
import { update } from '../api'

import { Container, makeStyles, createStyles } from '@material-ui/core'
import { StoreForm } from './StoreForm'
import { useHistory } from 'react-router-dom'

interface Props {
    store: StoreDto
}

export const EditForm: FC<Props> = ({
    store
}) => {
    const styles = useStyles()
    const history = useHistory()
    const { state, callApi } = useApi()

    const {
        values,
        errors,
        handleChange,
        submitForm
    } = useFormik<StoreDto>({
        initialValues: store,
        onSubmit: (values: StoreDto) => {
            sendData(values)
        },
        validationSchema: storeSchema,
        validateOnChange: true
    })

    const sendData = (store: StoreDto) => {
        callApi(update(store))
            .then(() => {
                history.goBack()
            })
    }

    const handleSubmitForm = () => {
        submitForm()
    }

    return (
        <Container className={styles.editContainer}>
            <StoreForm values={values} errors={errors} handleChange={handleChange} />
            <div className={styles.buttonContainer}>
                <LoadingButton loading={state.status === Status.Loading} onClick={handleSubmitForm}>
                    Editar
                </LoadingButton>
            </div>
        </Container>
    )
}

const useStyles = makeStyles(
    createStyles({
        editContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: 10,
        },
        editInput: {
            marginBottom: 30,
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
        }
    })
)