import * as yup from 'yup'

const codeYup = yup
    .string()
    .required('Este campo es requerido')

const nameYup = yup
    .string()
    .required('Este campo es requerido')

const streetNameYup = yup
    .string()
    .required('Este campo es requerido')

const streetNumberYup = yup
    .string()
    .required('Este campo es requerido')


const stateYup = yup
    .string()
    .required('Este campo es requerido')

const neighborhoodYup = yup
    .string()
    .required('Este campo es requerido')

const latitudeYup = yup
    .string()
    .required('Este campo es requerido')

const activeYup = yup
    .string()
    .required('Este campo es requerido')

export const storeSchema = yup.object().shape({
    code: codeYup,
    name: nameYup,
    streetName: streetNameYup,
    streetNumber: streetNumberYup,
    state: stateYup,
    neighborhood: neighborhoodYup,
    latitude: latitudeYup,
    active: activeYup,
})