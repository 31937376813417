import React, { FC } from 'react'
import { 
    Button, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    makeStyles,
    createStyles, 
    Typography
} from '@material-ui/core'
import { LoadingButton } from '../Button/LoadingButton'

interface Props {
    open: boolean
    title?: string
    loading?: boolean
    error?: string
    cancelButton?: {
        title: string
        action: () => void
    }
    successButton?: {
        title: string
        action: () => void
    }
}

export const Modal: FC<Props> = ({
    children,
    open,
    title,
    loading,
    error,
    cancelButton,
    successButton
}) => {
    const styles = useStyles()

    return (
        <Dialog fullWidth={true} maxWidth='sm' open={open} >
            {
                title &&
                <DialogTitle>{title}</DialogTitle>
            }
            <DialogContent>
                { children }
                {
                    error &&
                    <Typography align='right' color='error'>{error}</Typography>
                }
            </DialogContent>
            <DialogActions className={styles.dialogAction}>
                {
                    cancelButton &&
                    <Button variant='text' color='primary' onClick={cancelButton.action}>{cancelButton.title}</Button>
                }
                {
                    successButton &&
                    <LoadingButton loading={loading} onClick={successButton.action}>{successButton.title}</LoadingButton>
                }
            </DialogActions>
        </Dialog>
    )
}


const useStyles = makeStyles(
    createStyles({
        dialogAction: {
            display: 'flex',
            marginTop: 30,
        }
    })
)