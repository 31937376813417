import axios from 'axios'

import config from '../../../config'
import { CourierServiceCreateDto } from '../DTO/CourierServiceCreateDto'
import { CourierServiceDto } from '../DTO/CourierServiceDto'


export const getList = ( courierId : number, pageIndex = 0, pageSize = 10) => {
    const url = new URL('CourierService', config.apis.mcim)
    url.searchParams.set('courierId', courierId.toString())
    url.searchParams.set('pageIndex', pageIndex.toString())
    url.searchParams.set('pageSize', pageSize.toString())
   
    return axios.get(url.toString())
}

export const get = (id: string) => {
    const url = new URL(`CourierService/${id}`, config.apis.mcim)
    return axios.get(url.toString())
}

export const create = (courierService: CourierServiceCreateDto) => {
    const url = new URL('CourierService', config.apis.mcim)
    return axios.post(url.toString(), courierService)
}

export const update = (courierService: CourierServiceDto) => {
    const url = new URL(`CourierService/${courierService.courierServiceId}`, config.apis.mcim)
    return axios.put(url.toString(), courierService)
}

export const remove = (id: string) => {
    const url = new URL(`CourierService/${id}`, config.apis.mcim)
    return axios.delete(url.toString())
}


