import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { StandardPage } from '../../components/_EOS/StandardPage'
import { State } from '../../components/_EOS/State'
import { DispatchStateTable } from './DispatchStateTable'
import { useApi } from '../../hooks/useApi'
import { usePagination } from '../../hooks/usePagination'
import { useNotification } from '../../hooks/useNotification'
import { DispatchStateCreate } from './DispatchStateCreate'
import { DispatchStateSearch } from './DispatchStateSearch'
import { ApiResponse } from '../../typings/ApiResponse'
import { DispatchStateDto } from './entities/DispatchStateDto'
import { AlertDialog } from '../../components/Dialog/AlertDialog'
import { getList, remove } from './api'
import { SnackBar } from '../../components/Notification/Snackbar'

interface Props {

}

export const DispatchStateList: FC<Props> = () => {
    const history = useHistory()
    const { path } = useRouteMatch()
    const { state, callApi } = useApi<ApiResponse<DispatchStateDto>>()
    const pagination = usePagination(5)
    const [createModalOpen, setCreateModalOpen] = useState(false)

    const [dispatchStateName, setDispatchStateName] = useState('')
    const [dispatchStateCode, setDispatchStateCode] = useState('')
    const [currentDispatchStateId, setcurrentDispatchStateId] = useState(0)
    const [openDialog, setOpenDialog] = useState(false)
    const notification = useNotification()

    const fetchData = (dispatchStateId?: string, name?: string, onlyActive = false, page?: number, pageSize?: number) => {
        const pageParameter = page !== undefined ? page : pagination.page
        const pageSizeParameter = pageSize !== undefined ? pageSize : pagination.pageSize
        callApi(getList(dispatchStateId, name, onlyActive, pageParameter, pageSizeParameter))
    }

    useEffect(() => {
        console.log(state)
        fetchData()
    }, [])

    const handleActionButton = useCallback(() => {
        setCreateModalOpen(true)
    }, [])

    const handleEdit = (id: number) => {
        history.push(`${path}/edit/${id}`)
    }

    const handleModalClose = (success?: boolean) => {
        if (success) {
            fetchData()
            pagination.reset()
            setDispatchStateName('')
            setDispatchStateCode('')
            notification.success("El registro se ha realizado de manera exitosa")
        }
        setCreateModalOpen(false)
    }

    const handlePaginationChange = (page: number, pageSize: number) => {
        fetchData(dispatchStateCode, dispatchStateName, false, page, pageSize)
        pagination.setPagination(page, pageSize)
    }

    const handleSearch = () => {
        fetchData(dispatchStateCode, dispatchStateName, false, 0, 5)
        pagination.reset()
    }

    const handleReset = () => {
        fetchData()
        setDispatchStateName('')
        setDispatchStateCode('')
    }


    const handleDelete = (id: number) => {
        setcurrentDispatchStateId(id)
        setOpenDialog(true)
    }

    const okDialogAction = () => {
        setOpenDialog(false)
        remove(currentDispatchStateId.toString())
            .then(() => {
                fetchData(dispatchStateCode, dispatchStateName, false, pagination.page, pagination.pageSize)
                notification.success("El registro se eliminó del sistema")
            })
            .catch(() => {
                notification.error("No se puede eliminar el registro del courier en el sistema. Revise las dependecias asociadas")
            })
    }

    const cancelDialogAction = () => {
        setOpenDialog(false)
    }

    return (
        <Fragment>
            <StandardPage pageTitle='Administrar Estados Homologados' buttonTitle='Crear' buttonAction={handleActionButton}>
                <DispatchStateSearch
                    dispatchStateCode={dispatchStateCode}
                    onDispatchStateCodeChange={setDispatchStateCode}
                    dispatchStateName={dispatchStateName}
                    onDispatchStateNameChange={setDispatchStateName}
                    onSearch={handleSearch}
                    onReset={handleReset} />
                <State state={state} loadingMessage='Cargando Estados'>
                    <DispatchStateTable
                        data={state.data?.items}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        pagination={{
                            totalItem: state.data?.totalItems || 0,
                            page: pagination.page,
                            pageSize: pagination.pageSize,
                        }}
                        onPaginationChange={handlePaginationChange}
                    />
                </State>
            </StandardPage>
            <DispatchStateCreate open={createModalOpen} onClose={handleModalClose} />
            <AlertDialog title='MCIM' dialogText='¿Está seguro que desea eliminar el Estado Homologado del sistema?' dialogOpen={openDialog} onOkAction={okDialogAction} onCancelAction={cancelDialogAction}></AlertDialog>
            <SnackBar
                open={notification.snackBarOpen}
                setOpen={notification.handleSnackbar}
                message={notification.snackBarMessage}
                status={notification.snackBarStatus}
            ></SnackBar>
        </Fragment>
    )
}