/* 
    Copyright 2022-06-23 por Diego Andres Estrada Holding S.A ©
    Detalle: Se agrega a EOS mantenedor de configuraciones internacionales POS
*/
import React, { FC, useEffect } from 'react'
import { makeStyles, createStyles, InputLabel, FormControlLabel, Switch, FormLabel, FormControl, Select, MenuItem, Checkbox, ListItemText } from '@material-ui/core'
import { Config } from './entities/InternacionalizationInfoDto'

interface Props {
    config: Config,
    values: Config,
    onHandleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    },
    setDocument: React.Dispatch<React.SetStateAction<{status:number,document:number[]}>>,
    document: {status:number,document:number[]},
}

export const ConfigIdentifierClient: FC<Props> = ({
    config,
    values,
    onHandleChange,
    setDocument,
    document
}) => {
    const styles = useStyles()

    useEffect(() => {
        values.internacionalization = config.internacionalization
        values.documentType = config.documentType
        setDocument({ status:2,document: config.documentType.filter((document) => document.active).map((document) => document.documentTypeId) } )
    }, [])

    const handleChangeValue = (event: any) => {
        setDocument({ status:2,document: event.target.value } )
    }

    return (
        <div className={styles.container}>

            <InputLabel id="label-title">Configuracion Identificador Cliente</InputLabel>
            <div className={styles.container1}>
                <FormControlLabel
                    value="start"
                    control={<Switch color="primary" defaultChecked={config.internacionalization.showIdentificationTypes} onChange={onHandleChange('internacionalization.showIdentificationTypes')} />}
                    label={<FormLabel component="legend">Mostrar tipos de identificación</FormLabel>}
                    labelPlacement="start"
                />
                <FormControl variant="outlined" size="small">
                    <InputLabel>tipos de identificación</InputLabel>
                    <Select
                        multiple
                        defaultValue={config.documentType.filter((document) => document.active).map((document) => document.documentTypeId)}
                        onChange={handleChangeValue}
                        label="tipos de identificación"
                        renderValue={(selected:any) => (
                              selected.map((value:number) => (
                                config.documentType.find((item) => item.documentTypeId===value)?.name + ', '
                            ))
                        )}
                        MenuProps={MenuProps}
                    >
                        {config.documentType.map((item) => (
                            <MenuItem key={item.documentTypeId} value={item.documentTypeId}>
                                <Checkbox checked={document.document.includes(item.documentTypeId)} />
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {
                    document.status === 2  &&
                    <FormControl variant="outlined" size="small">
                        <InputLabel>Identificación Preseleccionada</InputLabel>
                        <Select
                            native
                            defaultValue={config.internacionalization.defaultDocumentTypeId}
                            onChange={onHandleChange('internacionalization.defaultDocumentTypeId')}
                            label="Identificación Preseleccionada"
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                            }}
                        >
                            {
                                config.documentType.filter((item)=> document.document.includes(item.documentTypeId)).map((document) => {
                                    return (
                                        <option key={document.documentTypeId} value={document.documentTypeId}>{document.name}</option>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'grid',
            gridTemplateRows: '0.7fr 1fr',
        },
        container1: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            columnGap: '3%'
        },
    })
)

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
