import * as yup from 'yup'


const typeYup = yup    
    .string()
    .required('Este campo es requerido')


const keyYup = yup
    .string()
    .required('Este campo es requerido')


const valueYup = yup    
    .string()
    .required('Este campo es requerido')

export const courierParameterSchema = yup.object().shape({
    type: typeYup, 
    key: keyYup, 
    value: valueYup,
})