import React, { FC } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { DispatchStateList } from './DispatchStateList'
import { DispatchStateEdit } from './DispatchStateEdit'

interface Props {

}

export const DispatchStatePage: FC<Props> = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path} component={DispatchStateList}/>
            <Route exact path={`${path}/edit/:id`} component={DispatchStateEdit}/>
        </Switch>
    )
}