import React, { FC } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
    createStyles,
    IconButton,
    TableContainer
} from '@material-ui/core'

import {
    Edit as EditIcon,
} from '@material-ui/icons'
import { SellerDto } from './entities/SellerDto'
import { Pagination, PaginationProps } from '../../components/Pagination'

interface Props extends PaginationProps{
    data?: SellerDto []
    
    onEdit?: (id: number) => void
}

export const SellerTable: FC<Props> = ({
    data = [],
    onEdit,
    pagination,
    onPaginationChange
}) => {
    const styles = useStyles()
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>Nombre</TableCell>
                        <TableCell align='center'>Mail</TableCell>
                        <TableCell align='center'>Estado</TableCell>
                        <TableCell align='center'>Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map(item => {
                            return (
                                <TableRow key={item.sellerId}>
                                    <TableCell align='center'>{item.name}</TableCell>
                                    <TableCell align='center'>{item.email}</TableCell>
                                    <TableCell align='center'>{item.active ? 'Activo' : 'Inactivo'}</TableCell>
                                    <TableCell align='center'>
                                        <div className={styles.action}>
                                            <IconButton onClick={() => onEdit && onEdit(item.sellerId)}>
                                                <EditIcon />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            {data.length===0 &&
              <div className={styles.notCoincidence}>No hay coincidencias</div>
            }
            <Pagination pagination={pagination} onPaginationChange={onPaginationChange}/>
        </TableContainer>
    )
}

const useStyles = makeStyles(
    createStyles({
        action: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
        },
        notCoincidence: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        }
    })
)
