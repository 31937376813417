import React, { FC } from 'react'
import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core'


import { FormikErrors } from 'formik'

import { CourierDto } from '../DTO/CourierDto'

interface Props {
    values: any
    errors: FormikErrors<CourierDto>
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    }
}

export const CreateForm: FC<Props> = ({
    values,
    errors,
    handleChange
}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <TextField 
                    label="Código" 
                    value={values.courierCode} 
                    onChange={handleChange('courierCode')} 
                    error={ !!errors.courierCode }
                    helperText={ errors.courierCode }
                    inputProps={{ maxLength: 12 }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={9}>
                <TextField 
                    label="Nombre del Courier" 
                    value={values.name} 
                    onChange={handleChange('name')} 
                    error={ !!errors.name }
                    helperText={ errors.name }
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                />
            </Grid>   

            <Grid item xs={12}>
                <FormControlLabel control={<Switch checked={values.active} onChange={handleChange('active')} />} label='Activo'/>
            </Grid>
        </Grid>
    )
}