import React, { FC, useEffect } from 'react'
import { 
    Grid, 
    TextField, 
    FormControl, 
    InputLabel, 
    FormControlLabel, 
    Switch, 
    FormHelperText, 
    Select,
    MenuItem } from '@material-ui/core'

import { useApi } from '../../../hooks/useApi'
import { getTypeList } from '../api'

import { FormikErrors } from 'formik'

import { DispatchStateDto } from '../entities/DispatchStateDto'
import { DispatchStateTypeDto } from '../entities/DispatchStateTypeDto'
import { ApiResponse } from '../../../typings/ApiResponse'




interface Props {
    values: any
    errors: FormikErrors<DispatchStateDto>
            
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    }
}

export const DispatchStateForm: FC<Props> = ({
    values,
    errors,
    handleChange
}) => {

    const dispatchStateApi = useApi<ApiResponse<DispatchStateTypeDto>>()
    
    useEffect(() => {
        dispatchStateApi.callApi(getTypeList(true, 0, 999))
    }, [])

    return (
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <TextField 
                    label="Nombre del Estado Homologado" 
                    value={values.stateName} 
                    onChange={handleChange('stateName')} 
                    error={ !!errors.stateName }
                    helperText={ errors.stateName }
                    fullWidth
                />
            </Grid>

            <Grid item xs={6}>
                <FormControl fullWidth error={!!errors.dispatchStateTypeId}>
                    <InputLabel id='labelDispatchStateType'>Tipo Estado</InputLabel>
                    <Select
                        labelId='labelDispatchStateType'
                        value={values.dispatchStateTypeId}
                        onChange={handleChange('dispatchStateTypeId')}
                    >
                        {
                            dispatchStateApi.state.data?.items.map(item => {
                                return (
                                    <MenuItem key={item.dispatchStateTypeId} value={item.dispatchStateTypeId}> {item.stateName} </MenuItem>
                                )
                            })
                        }
                    </Select>
                    {
                        !!errors.dispatchStateType?.dispatchStateTypeId && <FormHelperText>{errors.dispatchStateType?.dispatchStateTypeId}</FormHelperText>
                    }
                </FormControl>
            </Grid>


            <Grid item xs={12}>
                <FormControlLabel control={<Switch checked={values.active} onChange={handleChange('active')} />} label='Activo'/>
            </Grid>
        </Grid>
    )
}