/* 
    Copyright 2022-06-23 por Diego Andres Estrada Holding S.A ©
    Detalle: Se agrega a EOS mantenedor de configuraciones internacionales POS
*/
import React, { FC, Fragment, useEffect, useState } from 'react'

import { StandardPage } from '../../components/_EOS/StandardPage'
import { ConfigGeoPolitics } from './ConfigGeoPolitics'
import { ConfigIdentifierClient } from './ConfigIdentifierClient'
import { ConfigTax } from './ConfigTax'
import { State } from '../../components/_EOS/State'
import { useApi } from '../../hooks/useApi'
import { Config } from './entities/InternacionalizationInfoDto'
import { getList, update } from './api'
import { useFormik } from 'formik'
import initialValues from './utils/initialValues.json'
import { configSchema } from './schema/schema'

interface Props {

}

export const ConfigList: FC<Props> = () => {
    const { state, callApi } = useApi<Config>()

    const fetchData = () => {
        callApi(getList())
    }

    useEffect(() => {
        fetchData()
    }, [])

    const {
        values,
        // errors,
        handleChange,
        submitForm
    } = useFormik({
        initialValues: initialValues,
        onSubmit: values => {
            values &&
            sendData(values, document.document)
        },
        validationSchema: configSchema,
        validateOnChange: true
    })

    const handleSubmitForm = () => {
        submitForm()
    }

    const sendData = (Config: Config, active:number[]) => {
        callApi(update(Config,active))
            .then(() => {
                fetchData()
            })
    }

    const [ document, setDocument ] = useState({ status:1, document:[0] })

    return (
        <Fragment>
            <StandardPage pageTitle='Internacionalización' buttonTitle='Guardar Cambios' buttonAction={handleSubmitForm}>


                <State state={state} loadingMessage='Cargando Configuración Geopolitica'>
                    {
                        state.data?.internacionalization &&
                        <ConfigGeoPolitics config={state.data} values={values} onHandleChange={handleChange} />
                    }
                </State>
                <State state={state} loadingMessage='Cargando Configuración Tributaria'>
                    {
                        state.data?.internacionalization &&
                        <ConfigTax config={state.data} values={values} onHandleChange={handleChange} />
                    }
                </State>
                <State state={state} loadingMessage='Cargando Configuración Clientes'>
                    {
                        state.data?.internacionalization &&
                        <ConfigIdentifierClient 
                            config={state.data}
                            values={values}
                            setDocument={setDocument}
                            document={document}
                            onHandleChange={handleChange} />
                    }
                </State>

            </StandardPage>
        </Fragment>
    )
}