import React from 'react'
import { Config } from './typings/Config'
import {DispatchStatePage} from './pages/DispatchStates'
import {CourierPage} from './pages/Courier'
import {ActionDispatchStatePage} from './pages/ActionDispatchState'
export const configuration: Config = {
    name: 'mcim',
    visibleName: 'Mcim',
    iconUrl: 'mcim',
    url: '/mcim',
    submenus: [
        {
            name: 'Administrar',
            url: '',
            roles: ['MCIM.SuperAdmin'],
            component:undefined,
            items: [
                {
                    component: () => <ActionDispatchStatePage/>,
                    roles: ['MCIM.SuperAdmin'],
                    name: 'Acciones Estados Homologados',
                    url: 'actionDispatchStates'
                },
                {
                    component: () => <DispatchStatePage/>,
                    roles: ['MCIM.SuperAdmin'],
                    name: 'Estados Homologados',
                    url: 'dispatchStates'
                },
                {
                    component: () => <CourierPage/>,
                    roles: ['MCIM.SuperAdmin'],
                    name: 'Couriers',
                    url: 'courier'
                },

            ]
        },
    ]
}