import * as yup from 'yup'

const ONLY_LETTERS_REGEX = /^[a-zA-Záéíóú\-\sñÑ]+$/
const RUT_REGEX = /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/

const validateRut = (rut: string) => {
    let T = Number.parseInt(rut.split('-')[0].replace(/[^\dkK]/g, ''))

    let M = 0
    let S = 1
    for(;T; T = Math.floor(T/10)) {
        S = (S + T % 10 * (9 - M++ % 6)) % 11
    }

    const dv = (S ? S - 1 : 'K').toString()

    return dv.toLowerCase() === rut.split('').pop()?.toLocaleLowerCase()
}

const onlyLettersYup = yup
    .string()
    .matches(ONLY_LETTERS_REGEX, 'Solo letras')
    .required('Este campo es requerido')

const rutYup = yup
    .string()
    .matches(RUT_REGEX, 'El rut debe tener el formato 11.111.111-K')
    .test({
        name: 'Valid Rut',
        message: 'El rut no es válido',
        test: value => {
            return validateRut(value || '')
        },
    })
    .required('Este campo es requerido')

const emailYup = yup
.string()
.email('El campo debe contener un email válido')
.required('Este campo es requerido')

export const sellerSchema = yup.object().shape({
    dni: rutYup,
    name: onlyLettersYup,
    email: emailYup
})