import React, { FC } from 'react'
import { useFormik } from 'formik'
import { createStyles, makeStyles } from '@material-ui/core'

import { CourierCreateDto } from './DTO/CourierCreateDto'
import { Modal } from '../../components/_EOS/Modal'
import { CreateForm } from './Forms/CreateForm'
import { useApi } from '../../hooks/useApi'
import { create } from './api'
import { Status } from '../../hooks/useFetchState'
import { courierSchema } from './Forms/Schema'
interface Props {
    open: boolean
    onClose: (success?: boolean) => void
}

export const CourierCreate: FC<Props> = ({
    open,
    onClose,
}) => {
    const { state, callApi } = useApi()

    const styles = useStyles()

    const {
        values,
        errors,
        handleChange,
        resetForm,
        submitForm
    } = useFormik({
        initialValues: {
            courierCode: '',
            name: '',
            active: true,
        },
        onSubmit: values => {
            sendData(values)
        },
        validateOnChange: true,
        validationSchema: courierSchema
    })

    const sendData = (courier: CourierCreateDto) => {
        callApi(create(courier))
            .then(() => {
                resetForm()
                onClose(true)
            })
    }

    const handleSuccessButton = () => {
        submitForm()
    }

    const handleClose = () => {
        resetForm()
        onClose()
    }

    return (
        <Modal
            open={open}
            title='Crear courier'
            loading={state.status === Status.Loading}
            error={state.status === Status.Error ? state.error : undefined}
            cancelButton={{
                title: 'Cancelar',
                action: handleClose
            }}
            successButton={{
                title: 'Guardar',
                action: handleSuccessButton
            }}>

            <div className={styles.container}>
                <CreateForm values={values} errors={errors} handleChange={handleChange}/>
            </div>

        </Modal>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        input: {
            marginBottom: 20,
        }
    })
)
