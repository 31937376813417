import React, { FC, useState } from 'react'
import {
    Typography,
    makeStyles,
    createStyles
} from '@material-ui/core'

import { PaymentMethodItem } from '../../../components/PaymentMethodItem'
import { PaymentMethodPosConfiguration } from './PaymentMethodPosConfiguration'
import { PaymentMethodPosDto } from '../entities/PaymentMethodPosDto'
import { PaymentMethodData } from '../entities/PaymentMethodData'

interface Props {
    posCode: string
    paymentMethodPos?: PaymentMethodPosDto[]
    onReset: () => void
}

const paymentMethods = [
    {
        code: 'PO',
        name: 'Pago en caja',
    },
    {
        code: 'MP',
        name: 'Mercado pago',
    },
    {
        code: 'ML',
        name: 'Mercado pago link',
    },
    {
        code: 'FP',
        name: 'FPay',
    },
]

export const PaymentMethodPos: FC<Props> = ({
    posCode,
    paymentMethodPos = [],
    onReset
}) => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodData>()

    const styles = useStyles()

    const data: PaymentMethodData[] = paymentMethods.map(paymentMethod => {
        const paymentMethodFound = paymentMethodPos.find(p => p.paymentMethodCode === paymentMethod.code)

        const info = !paymentMethodFound
                     ? 'Sin configurar'
                     : paymentMethodFound.active
                        ? 'Activado'
                        : 'Desactivado'

        return {
            ...paymentMethod,
            info,
            paymentMethod: paymentMethodFound
        }
    })

    const handleModalClose = (success?: boolean) => {
        if(success) {
            onReset()
        }

        setSelectedPaymentMethod(undefined)
    }

    return (
        <div>
            <Typography variant='h5' component='h2'>Medios de pago asociados</Typography>

            <div className={`${styles.content}`}>
                {
                    data.map((item) => 
                        <PaymentMethodItem
                            key={item.code}
                            title={item.name}
                            info={item.info}
                            buttonText='Configurar'
                            buttonAction={() => setSelectedPaymentMethod(item)}
                        />
                    )
                }
            </div>
            {
                selectedPaymentMethod &&
                <PaymentMethodPosConfiguration
                    open={!!selectedPaymentMethod}
                    posCode={posCode}
                    onClose={handleModalClose} 
                    PaymentMethodPosData={selectedPaymentMethod} 
                />
            }
        </div>
    )
}

const useStyles = makeStyles(
    createStyles({
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        content: {
            marginTop: 20,
        },
        titleContainer: {
            flex: 1,
        },
        actionContainer: {
            flex: 0.25,
            display: 'flex',
            justifyContent: 'space-between'
        }
    })
)

