interface Config {
    apis: {
        [key: string]: string
    }
}

const config: Config = {
    apis: {        
        //mcim: 'https://localhost:44379/api/mcim'
        //mcim: 'https://app-apis.azure-api.net/mcimqa/api/mcim',
        //mcim: 'https://app-apis.azure-api.net/mcimdev/api/mcim',
        mcim: 'https://api-eos.azure-api.net/mcim/api/mcim',
    }
}
export default config