import React, { FC } from 'react'
import { useFormik } from 'formik'
import { createStyles, makeStyles, FormControlLabel, Switch, TextField } from '@material-ui/core'

export interface IMercadoPagoForm {
    active: boolean
    ExternalReference: string
    QrUrl: string
}

export const initialValues: IMercadoPagoForm = {
    active: false,
    ExternalReference: '',
    QrUrl: ''
}

interface Props extends ReturnType<typeof useFormik> {
    
}

export const MercadoPagoForm: FC<Props> = ({
    values,
    handleChange
}) => {
    const styles = useStyles()

    return (
        <div className={styles.container}>
            <FormControlLabel
                className={styles.input}
                label={values.active ? 'Activado' : 'Desactivado'}
                control={
                    <Switch checked={values.active} onChange={handleChange('active')}/>
                }
            />

            <TextField contentEditable={false} label="ExternalReference" value={values.ExternalReference} className={styles.input} />

            {
                values.QrUrl &&
                <a className={styles.qrContainer} href={values.QrUrl} target="_blank" rel="noopener noreferrer">
                    <img src={values.QrUrl} alt='Qr mercadopago' className={styles.qr}/>
                </a>
            }

        </div>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        input: {
            marginBottom: 20,
        },
        qrContainer: {
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0',
        },
        qr: {
            width: '50%',
            aspectRatio: '1',
        }
    })
)