import React, { FC } from 'react'
import { useFormik } from 'formik'
import { createStyles, makeStyles } from '@material-ui/core'
import { CourierParameterCreateDto } from './DTO/CourierParameterCreateDto'
import { CourierParameterTypeDto } from './DTO/CourierParameterTypeDto'
import { Modal } from '../../components/_EOS/Modal'
import { CourierParameterForm } from './forms/CourierParameterForm'
import { useApi } from '../../hooks/useApi'
import { create } from './api'
import { Status } from '../../hooks/useFetchState'
import { courierParameterSchema } from './forms/Schema'


interface Props {
    id : number,
    parameterTypes? : CourierParameterTypeDto[]
    open: boolean    
    onClose: (success?: boolean) => void
}

export const CourierParameterCreate: FC<Props> = ({
    id,
    parameterTypes,
    open,
    onClose,
}) => {
    const { state, callApi } = useApi()

    const styles = useStyles()    
  
    const {
        values,
        errors,
        handleChange,
        resetForm,
        submitForm
    } = useFormik({
        initialValues: {          
            type : 0,
            courierId : id,
            key: '',
            value: '',
        },
        onSubmit: values => {
            sendData(values)
        },
        validateOnChange: true,
        validationSchema: courierParameterSchema
    })

    const sendData = (courierParameter: CourierParameterCreateDto) => {
        callApi(create(courierParameter))
            .then(() => {
                resetForm()
                onClose(true)
            })
    }

    const handleSuccessButton = () => {
        submitForm()
    }

    const handleClose = () => {
        resetForm()
        onClose()
    }

    return (
        <Modal
            open={open}
            title='Crear Parámetro'
            loading={state.status === Status.Loading}
            error={state.status === Status.Error ? state.error : undefined}
            cancelButton={{
                title: 'Cancelar',
                action: handleClose
            }}
            successButton={{
                title: 'Guardar',
                action: handleSuccessButton
            }}>

            <div className={styles.container}>
                <CourierParameterForm parameterTypes={parameterTypes} values={values} errors={errors} handleChange={handleChange}/>
            </div>

        </Modal>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        input: {
            marginBottom: 20,
        }
    })
)
