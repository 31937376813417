/* 
    Copyright 2022-06-23 por Diego Andres Estrada Holding S.A ©
    Detalle: Se agrega a EOS mantenedor de configuraciones internacionales POS
*/

import axios from 'axios'

import config from '../../../config'
import { Config } from '../entities/InternacionalizationInfoDto'
import { UpdateKeyDto } from '../entities/UpdateKeyDto'


export const getList = () => {
    const url = new URL('pos/1/config', config.apis.pos)
    return axios.get(url.toString())
}

const updateDocument = (active: number[],configu: Config) => {
    let url = new URL(`DocumentType/`, config.apis.pos)

    configu.documentType.map((activated)=>{
        url = new URL(`DocumentType/${activated.documentTypeId}`, config.apis.pos)
        if (active.includes(activated.documentTypeId)){
        return axios.put(url.toString(), { Active: true })
        }
        else {
            return axios.put(url.toString(), { Active: false })
        }
    })
}

export const update = (configu: Config, active: number[]) => {
    updateDocument(active,configu)
    let url = new URL(`configuration/`, config.apis.pos)
    let sendKey: UpdateKeyDto = {
        Key: '',
        Value: '',
        Group: 'Internationalization'
    }

    for (const key in configu.internacionalization) {
        url = new URL(`configuration/${key}`, config.apis.pos)
        sendKey = {
            Key: key,
            Value: configu.internacionalization[key],
            Group: 'Internationalization'
        }
        if(key==='showDecimals') {
            sendKey.Value = configu.internacionalization[key] === true ? '1' : '0'
        }
        if(key==='flatTaxRate') {
            sendKey.Value = configu.internacionalization[key] === true ? '1' : '0'
        }
        if(key==='showIdentificationTypes') {
            sendKey.Value = configu.internacionalization[key] === true ? '1' : '0'
        }

        axios.put(url.toString(), sendKey)
    }
    return axios.put(url.toString(), sendKey)
}
