import React, { FC, Fragment } from 'react'
import { Button, ButtonProps, CircularProgress } from '@material-ui/core'

interface Props extends ButtonProps{
    loading?: boolean
}

export const LoadingButton:FC<Props> = ({
    children,
    loading,
    ...buttonProps
}) => {
    return(
        <Fragment>
            <Button variant='contained' color='primary' {...buttonProps} disabled={loading}>
                {
                    loading
                    ? <CircularProgress size={14} />
                    : children
                }
            </Button>
        </Fragment>
    )
}