import { useState } from 'react'

export const useNotification = () => {
    
    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false)
    const [snackBarMessage, setSnackBarMessage] = useState<string>('')
    const [snackBarStatus, setSnackBarStatus] = useState<string>('')
    
    const notify = (message: string, status: string) => {
      setSnackBarMessage(message)
      setSnackBarStatus(status)
      setSnackBarOpen(true)
    }

    const success = (message: string) => {
      notify(message, "success")
    }

    const error = (message: string) => {
      notify(message, "error")
    }

    const warning = (message: string) => {
      notify(message, "warning")
    }

    const info = (message: string) => {
      notify(message, "info")
    }
    
    const handleSnackbar = (isOpen: boolean) => {
      setSnackBarOpen(isOpen)
    }
    

    return {
        snackBarOpen,
        snackBarMessage,
        snackBarStatus,
        notify,
        success,
        error,
        warning,
        info,
        handleSnackbar
    }
}
