import React, { FC } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { CourierList } from './CourierList'
import { CourierEdit } from './CourierEdit'

interface Props {

}

export const CourierPage: FC<Props> = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path} component={CourierList}/>
            <Route exact path={`${path}/edit/:id`} component={CourierEdit}/>
        </Switch>
    )
}