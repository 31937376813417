import React, { FC, Fragment } from 'react'
import { TablePagination } from '@material-ui/core'

export interface PaginationProps {
    pagination?: {
        page: number
        totalItem: number
        pageSize: number
    }
    onPaginationChange?: (page: number, pageSize: number) => void 
}

export const Pagination: FC<PaginationProps> = ({
    pagination,
    onPaginationChange
}) => {
    return (
        <Fragment>
            {
                pagination &&
                <TablePagination

                    rowsPerPageOptions ={[2, 5, 10, 20]}
                    labelRowsPerPage="Filas por página"
                    component="div"
                    count={pagination?.totalItem || 0}
                    page={pagination?.page || 0}
                    rowsPerPage={pagination?.pageSize || 5}
                    onChangePage={(_, page) => onPaginationChange && onPaginationChange(page, pagination?.pageSize !== undefined ? pagination?.pageSize : 5)}
                    onPageChange={(_, page) => onPaginationChange && onPaginationChange(page, pagination?.pageSize !== undefined ? pagination?.pageSize : 5)}
                    onChangeRowsPerPage={(event) => onPaginationChange && onPaginationChange(pagination?.page !== undefined ? pagination?.page : 5, parseInt(event.target.value as string))}                />
            }
        </Fragment>
    )
}