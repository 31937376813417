import React, { FC } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { PosList } from './PosList'
import { PosEdit } from './PosEdit'

interface Props {

}

export const PosPage:FC<Props> = () => {
    const { path } = useRouteMatch()

    return(
        <Switch>
            <Route exact path={path} component={PosList} />
            <Route exact path={`${path}/edit/:id`} component={PosEdit} />
        </Switch>
    )
}