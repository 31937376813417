import React, { FC, useEffect } from 'react'
import { useFormik } from 'formik'
import { LoadingButton } from '../../../components/_EOS/Button/LoadingButton'
import { useHistory } from 'react-router-dom'

import { PosDto } from '../entities/PosDto'
import { updatePosSchema } from './schema'

import { ApiResponse } from '../../../typings/ApiResponse'
import { useApi } from '../../../hooks/useApi'

import { update } from '../api'

import { StoreMinInfoDto } from '../../stores/entities/StoreMinInfoDto'
import { getList } from '../../stores/api'

import {
    Container,
    makeStyles,
    createStyles,
    TextField,
    FormControlLabel,
    Switch,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core'
import { Status } from '../../../hooks/useFetchState'

interface Props {
    pos: PosDto
}

export const EditForm: FC<Props> = ({
    pos
}) => {
    const styles = useStyles()
    const history = useHistory()

    const posApi = useApi()
    const storeApi = useApi<ApiResponse<StoreMinInfoDto>>()

    useEffect(() => {
        storeApi.callApi(getList(undefined, undefined, true, 0, 999))
    }, [])

    const {
        values,
        errors,
        handleChange,
        submitForm
    } = useFormik<PosDto>({
        initialValues: pos,
        onSubmit: (values: PosDto) => {
            sendData(values)
        },
        validationSchema: updatePosSchema,
        validateOnChange: true
    })

    const sendData = (pos: PosDto) => {
        posApi.callApi(update(pos))
            .then(() => {
                history.goBack()
            })
    }

    const handleSubmitForm = () => {
        submitForm()
    }

    return (
        <Container className={styles.editContainer}>
            <TextField
                className={styles.editInput}
                label="Nombre"
                inputProps={{ maxLength: 50 }}
                value={values.name}
                onChange={handleChange('name')}
                error={!!errors.name}
                helperText={errors.name}
            />
            <TextField
                className={styles.editInput}
                label="Código"
                inputProps={{ maxLength: 50 }}
                value={values.posCode}
                onChange={handleChange('posCode')}
                error={!!errors.posCode}
                helperText={errors.posCode}
            />
            <FormControl fullWidth error={!!errors.storeId}>
                <InputLabel id='labelStoreId'>Tienda</InputLabel>
                <Select
                    labelId='labelStoreId'
                    value={values.storeId || ''}
                    onChange={handleChange('storeId')}
                >
                    <MenuItem value=''>Seleccione tienda</MenuItem>
                    {
                        storeApi.state.data?.items.map(item => {
                            return(
                                <MenuItem key={item.storeId} value={item.storeId}>{item.name}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            <FormControlLabel className={styles.editInput} control={<Switch checked={values.active} onChange={handleChange('active')} />} label='Activo' />

            <div className={styles.buttonContainer}>
                <LoadingButton loading={posApi.state.status === Status.Loading} onClick={handleSubmitForm}>
                    Editar
                </LoadingButton>
            </div>
        </Container>
    )
}

const useStyles = makeStyles(
    createStyles({
        editContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: 10,
        },
        editInput: {
            marginBottom: 30,
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
        }
    })
)