import React, { FC } from 'react'
import { Grid, TextField,  FormControl, InputLabel, Select, MenuItem, } from '@material-ui/core'
import { FormikErrors } from 'formik'
import { CourierParameterDto } from '../DTO/CourierParameterDto'
import { CourierParameterTypeDto } from '../DTO/CourierParameterTypeDto'

interface Props {
    values: any
    parameterTypes? : CourierParameterTypeDto[]
    errors: FormikErrors<CourierParameterDto>
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    }
}

export const CourierParameterForm: FC<Props> = ({
    values,
    parameterTypes,
    errors,
    handleChange
}) => {
       

    return (
        <Grid container spacing={2}>
            
            <Grid item xs={9}>
            <FormControl fullWidth error={!!errors.type}>
                <InputLabel id='labelStoreId'>Tipo de párametro</InputLabel>
                <Select
                    labelId='labelStoreId'
                    value={values.type || ''}
                    onChange={handleChange('type')}
                >
                    <MenuItem value=''>Seleccione tipo de parámetro</MenuItem>
                    {
                       parameterTypes && parameterTypes.map(item => {
                            return(
                                <MenuItem key={item.couierParameterTypeId} value={item.couierParameterTypeId}>{item.description}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            </Grid>
            <Grid item xs={9}>
                <TextField 
                    label="Nombre del parámetro" 
                    value={values.key} 
                    onChange={handleChange('key')} 
                    error={ !!errors.key }
                    helperText={ errors.key }
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={9}>
                <TextField 
                    label="Valor del parámetro" 
                    value={values.value} 
                    onChange={handleChange('value')} 
                    error={ !!errors.value }
                    helperText={ errors.value }
                    inputProps={{ maxLength: 250 }}
                    fullWidth
                />
            </Grid>      

        </Grid>
    )
}