import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { StandardPage } from '../../components/_EOS/StandardPage'
import { State } from '../../components/_EOS/State'
import { useApi } from '../../hooks/useApi'
import { StoreDto } from './entities/StoreDto'
import { PaymentMethodStoreDto } from './entities/PaymentMethodStoreDto'
import { PaymentMethodStore } from './paymentMethodStores/PaymentMethodStore'
import { get, getPaymentMethodStore } from './api'

import { EditForm } from './forms/EditForm'

interface Props {

}

export const StoreEdit: FC<Props> = () => {
    const { id } = useParams<{ id: string }>()
    const storeApi = useApi<StoreDto>()
    const paymentMethodApi = useApi<PaymentMethodStoreDto[]>()

    const fetchPaymentMethod = () => {
        if (storeApi.state.data) {
            const { code } = storeApi.state.data
            paymentMethodApi.callApi(getPaymentMethodStore(code))
        }
    }

    useEffect(() => {
        storeApi.callApi(get(id))
    }, [id])

    useEffect(() => {
        fetchPaymentMethod()
    }, [storeApi.state])

    return (
        <StandardPage pageTitle='Tienda' backAction={true}>
            <State state={storeApi.state} loadingMessage='Cargando Tienda'>
                {
                    storeApi.state.data &&
                        <EditForm store={storeApi.state.data} />
                }
            </State>
            {
                storeApi.state.data &&
                <State state={paymentMethodApi.state} loadingMessage='Cargando Metodos de pago'>
                    <PaymentMethodStore storeCode={storeApi.state.data.code} paymentMethodsStore={paymentMethodApi.state.data} onReset={fetchPaymentMethod} />
                </State>
            }
        </StandardPage>
    )
}