import axios from 'axios'

import config from '../../../config'
import { StoreCreateDto } from '../entities/StoreCreateDto'
import { StoreDto } from '../entities/StoreDto'
import { PaymentMethodStoreCreateDto } from '../entities/PaymentMethodStoreCreateDto'
import { PaymentMethodStoreUpdateDto } from '../entities/PaymentMethodStoreUpdateDto'

export const getList = (code?: string, name?: string, active = false, pageIndex = 0, pageSize = 10) => {
    const url = new URL('store', config.apis.pos)
    if (code) {
        url.searchParams.set('code', code)
    }
    if (name) {
        url.searchParams.set('name', name)
    }
    url.searchParams.set('pageIndex', pageIndex.toString())
    url.searchParams.set('pageSize', pageSize.toString())
    url.searchParams.set('active', active.toString())
    return axios.get(url.toString())
}

export const get = (id: string) => {
    const url = new URL(`store/${id}`, config.apis.pos)
    return axios.get(url.toString())
}

export const create = (store: StoreCreateDto) => {
    const url = new URL('store', config.apis.pos)
    return axios.post(url.toString(), store)
}

export const update = (store: StoreDto) => {
    const url = new URL(`store/${store.storeId}`, config.apis.pos)
    return axios.put(url.toString(), store)
}

export const getPaymentMethodStore = (storeCode: string) => {
    const url = new URL(`paymentmethodstore/${storeCode}`, config.apis.pos)
    return axios.get(url.toString())
}

export const createPaymentMethodStore = (paymentMethodStore: PaymentMethodStoreCreateDto) => {
    const url = new URL(`paymentmethodstore`, config.apis.pos)
    return axios.post(url.toString(), paymentMethodStore)
}

export const updatePaymentMethodStore = (storeCode: string, paymentMethodCode: string, paymentMethodStore: PaymentMethodStoreUpdateDto) => {
    const url = new URL(`paymentmethodstore/${storeCode}/${paymentMethodCode}`, config.apis.pos)
    return axios.put(url.toString(), paymentMethodStore)
}