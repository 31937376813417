/* 
    Copyright 2022-06-23 por Cristian Soto Ecomsur Holding S.A ©
    Detalle: Se agrega a EOS mantenedor de estados pedidos POS
*/

import React, { FC } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
    createStyles,
    IconButton,
    TableContainer
} from '@material-ui/core'

import {
    Edit as EditIcon,
} from '@material-ui/icons'
import { OrderStateDto } from './entities/OrderStateDto'

interface Props {
    data?: OrderStateDto [],
    onEdit?: (id: string) => void
}

export const OrderStatesTable: FC<Props> = ({
    data = [],
    onEdit,
}) => {
    const styles = useStyles()

    return(
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>Código</TableCell>
                        <TableCell align='center'>Nombre</TableCell>
                        <TableCell align='center'>Descripción</TableCell>
                        <TableCell align='center'>¿Envía email?</TableCell>
                        <TableCell align='center'>Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map(item => {
                            return(
                                <TableRow key={item.orderStateId}>
                                    <TableCell align='center'>{item.code}</TableCell>
                                    <TableCell align='center'>{item.name}</TableCell>
                                    <TableCell align='center'>{item.description}</TableCell>
                                    <TableCell align='center'>{item.sendEmail ? 'Si' : 'No'}</TableCell>
                                    <TableCell align='center'>
                                        <div className={styles.action}>
                                            <IconButton onClick={() => onEdit && onEdit(item.orderStateId.toString()) }>
                                                <EditIcon />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const useStyles = makeStyles(
    createStyles({
        action: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
        },
        notCoincidence: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        }
    })
)