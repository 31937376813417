import axios from 'axios'

import config from '../../../config'
import { ActionDispatchStateDto } from '../DTO/ActionDispatchStateDto'



export const getList = (code?: string, name?: string, pageIndex = 0, pageSize = 10) => {
    const url = new URL('ActionDispatchState', config.apis.mcim) 
    
    if (code) {
        url.searchParams.set('code', code)
    }
    if (name) {
        url.searchParams.set('name', name)
    }

    url.searchParams.set('pageIndex', pageIndex.toString())
    url.searchParams.set('pageSize', pageSize.toString())
   
    return axios.get(url.toString())
}

export const getAllList = () => {
    const url = new URL('DispatchState/GetAll', config.apis.mcim)    
    return axios.get(url.toString())
}

export const get = (id: string) => {
    const url = new URL(`ActionDispatchState/${id}`, config.apis.mcim)
    return axios.get(url.toString())
}

export const create = (actionDispatchState: ActionDispatchStateDto) => {
    const url = new URL('ActionDispatchState', config.apis.mcim)
    return axios.post(url.toString(), actionDispatchState)
}

export const update = (actionDispatchState: ActionDispatchStateDto) => {
    const url = new URL(`ActionDispatchState/${actionDispatchState.actionId}`, config.apis.mcim)
    return axios.put(url.toString(), actionDispatchState)
}

export const remove = (id: string) => {
    const url = new URL(`ActionDispatchState/${id}`, config.apis.mcim)
    return axios.delete(url.toString())
}

