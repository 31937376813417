import React, { FC } from 'react'
import { useFormik } from 'formik'
import { createStyles, makeStyles } from '@material-ui/core'
import { CourierDispatchStateDto } from './DTO/CourierDispatchStateDto'
import { DispatchStateDto } from  '../DispatchStates/entities/DispatchStateDto'
import { DispatchStateTypeDto } from '../DispatchStates/entities/DispatchStateTypeDto'
import { Modal } from '../../components/_EOS/Modal'
import { CourierDispatchStateForm } from './forms/CourierDispatchStateForm'
import { useApi } from '../../hooks/useApi'
import {  update } from './api'
import { Status } from '../../hooks/useFetchState'
import { courierDispatchStateSchema } from './forms/Schema'




interface Props {
    id : number
    open: boolean 
    dispatchStateType? : DispatchStateTypeDto[] 
    dispatchState? : DispatchStateDto[]
    courierDispatchState : CourierDispatchStateDto   
    onClose: (success?: boolean) => void
}

export const CourierDispatchStateEdit: FC<Props> = ({
    courierDispatchState,    
    dispatchStateType,
    dispatchState,
    open,
    onClose,
}) => {
    const { state, callApi } = useApi()

    const styles = useStyles()

    
    
    const {      
        values,
        errors,
        handleChange,
        resetForm,
        submitForm
    } = useFormik({
        enableReinitialize : true,
        initialValues: courierDispatchState,
        onSubmit: values => {
            sendData(values)
        },
        validateOnChange: true,
        validationSchema: courierDispatchStateSchema
    })   
    
    const sendData = (courierDispatchState: CourierDispatchStateDto) => {
        callApi(update(courierDispatchState))
            .then(() => {
                resetForm()
                onClose(true)
            })
    }

    const handleSuccessButton = () => {
        submitForm()
    }

    const handleClose = () => {
        resetForm()
        onClose()
    }



    return (
        <Modal
            open={open}
            title='Editar Estado'
            loading={state.status === Status.Loading}
            error={state.status === Status.Error ? state.error : undefined}
            cancelButton={{
                title: 'Cancelar',
                action: handleClose
            }}
            successButton={{
                title: 'Actualizar',
                action: handleSuccessButton
            }}>

            <div className={styles.container}>
                <CourierDispatchStateForm dispatchStateType={dispatchStateType} dispatchState={dispatchState} values={values} errors={errors} handleChange={handleChange}/>
            </div>

        </Modal>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        input: {
            marginBottom: 20,
        }
    })
)
