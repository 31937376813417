/* 
    Copyright 2022-06-23 por Cristian Soto Ecomsur Holding S.A ©
    Detalle: Se agrega a EOS mantenedor de estados pedidos POS
*/

import React, { FC, Fragment, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { StandardPage } from '../../components/_EOS/StandardPage'
import { State } from '../../components/_EOS/State'

import { OrderStateDto } from './entities/OrderStateDto'
import { OrderStatesTable } from './OrderStateTable'
import { getList } from './api'

import { useApi } from '../../hooks/useApi'

interface Props {

}

export const OrderStatesList: FC<Props> = () => {
    const history = useHistory()
    const { path } = useRouteMatch()
    const { state, callApi } = useApi<OrderStateDto[]>()

    const fetchData = () => {
        callApi(getList())
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleEdit = (id: string) => {
        history.push(`${path}/edit/${id}`)
    }

    return (
        <Fragment>
            <StandardPage pageTitle='Estados del Pedido'>
                <State state={state} loadingMessage='Cargando Estados'>
                    <OrderStatesTable 
                        data={state.data}
                        onEdit={handleEdit}
                    />
                </State>
            </StandardPage>
        </Fragment>
    )
}