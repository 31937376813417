import axios from 'axios'

import config from '../../../config'
import { DispatchStateCreateDto } from '../entities/DispatchStateCreateDto'
import { DispatchStateDto } from '../entities/DispatchStateDto'
import { ActionDispatchStateUpdateDto } from '../entities/ActionDispatchStateUpdateDto'


export const getList = (dispatchStateId?: string, name?: string, active = false, pageIndex = 0, pageSize = 10) => {
    const url = new URL('DispatchState', config.apis.mcim)
    if (dispatchStateId) {
        url.searchParams.set('dispatchStateId', dispatchStateId)
    }
    if (name) {
        url.searchParams.set('name', name)
    }
    url.searchParams.set('pageIndex', pageIndex.toString())
    url.searchParams.set('pageSize', pageSize.toString())
    url.searchParams.set('active', active.toString())
    return axios.get(url.toString())
}

export const getAllList = () => {
    const url = new URL('DispatchState/GetAll', config.apis.mcim)    
    return axios.get(url.toString())
}

export const getTypeList = (active = false, pageIndex = 0, pageSize = 10) => {
    const url = new URL('DispatchStateType', config.apis.mcim)
    url.searchParams.set('pageIndex', pageIndex.toString())
    url.searchParams.set('pageSize', pageSize.toString())
    url.searchParams.set('active', active.toString())
    return axios.get(url.toString())
}

export const getTypeAllList = () => {
    const url = new URL('DispatchStateType/GetAll', config.apis.mcim)    
    return axios.get(url.toString())
}


export const get = (id: string) => {
    const url = new URL(`DispatchState/${id}`, config.apis.mcim)
    return axios.get(url.toString())
}

export const create = (dispatchState: DispatchStateCreateDto) => {
    const url = new URL('DispatchState', config.apis.mcim)
    return axios.post(url.toString(), dispatchState)
}

export const update = (dispatchState: DispatchStateDto) => {
    const url = new URL(`DispatchState`, config.apis.mcim)
    return axios.put(url.toString(), dispatchState)
}

export const updateActions = (dispatchStateId: number, actions: number[]) => {
    const url = new URL(`ActionDispatchStateAssigned`, config.apis.mcim)
    const dto: ActionDispatchStateUpdateDto = { dispatchStateId, actions };
    return axios.put(url.toString(), dto)
}

export const remove = (id: string) => {
    const url = new URL(`DispatchState/${id}`, config.apis.mcim)
    return axios.delete(url.toString())
}