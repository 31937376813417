import React, { FC } from 'react'
import { makeStyles, createStyles, Button, IconButton, TextField, Grid, Tooltip } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'

interface Props {
    dispatchStateCode: string
    onDispatchStateCodeChange: (dispatchStateCode: string) => void
    dispatchStateName: string
    onDispatchStateNameChange: (dispatchStateName: string) => void
    onSearch: () => void
    onReset : () => void
}

export const DispatchStateSearch: FC<Props> = ({
    dispatchStateCode,
    onDispatchStateCodeChange,
    dispatchStateName,
    onDispatchStateNameChange,
    onSearch,
    onReset
}) => {
    const styles = useStyles()
    return (
        <div className={styles.container}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                    <TextField label="Ingrese Código" value={dispatchStateCode} onChange={(event) => onDispatchStateCodeChange(event.target.value as string)}/>
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                    <TextField label="Ingrese Nombre" value={dispatchStateName} onChange={(event) => onDispatchStateNameChange(event.target.value as string)}/>
                </Grid>
            </Grid>
            <div className={styles.buttonContainer}>
                <Tooltip title='Limpiar filtros'><IconButton color='primary' onClick={onReset}><RotateLeftIcon /></IconButton></Tooltip>
                <Button variant='text' color='primary' endIcon={<SearchIcon/>} onClick={onSearch}>Buscar</Button>
            </div>
        </div>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
        }
    })
)