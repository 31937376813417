import axios from 'axios'

const URL_BASE = 'https://api.mercadolibre.com/'

export const getCountry = (country: string) => {
    const url = new URL(`countries/${country}`, URL_BASE)
    return axios.get(url.toString())
}

export const getState = (state: string) => {
    const url = new URL(`states/${state}`, URL_BASE)
    return axios.get(url.toString())
}
