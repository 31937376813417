import axios from 'axios'

import config from '../../../config'
import { CourierDispatchStateCreateDto } from '../DTO/CourierDispatchStateCreateDto'
import { CourierDispatchStateDto } from '../DTO/CourierDispatchStateDto'


export const getList = ( courierId : number, pageIndex = 0, pageSize = 10) => {
    const url = new URL('CourierDispatchState', config.apis.mcim)
    url.searchParams.set('courierId', courierId.toString())
    url.searchParams.set('pageIndex', pageIndex.toString())
    url.searchParams.set('pageSize', pageSize.toString())
   
    return axios.get(url.toString())
}

export const get = (id: string) => {
    const url = new URL(`CourierDispatchState/${id}`, config.apis.mcim)
    return axios.get(url.toString())
}

export const create = (courierDispatchState: CourierDispatchStateCreateDto) => {
    const url = new URL('CourierDispatchState', config.apis.mcim)
    return axios.post(url.toString(), courierDispatchState)
}

export const update = (courierDispatchState: CourierDispatchStateDto) => {
    const url = new URL(`CourierDispatchState/${courierDispatchState.courierDispatchStateId}`, config.apis.mcim)
    return axios.put(url.toString(), courierDispatchState)
}

export const remove = (couirierId: string, id: string) => {
    const url = new URL(`CourierDispatchState/${couirierId}/${id}`, config.apis.mcim)
    return axios.delete(url.toString())
}

