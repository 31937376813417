import React, { FC, useEffect } from 'react' 
import { useParams } from 'react-router-dom'

import { StandardPage } from '../../components/_EOS/StandardPage'
import { State } from '../../components/_EOS/State'
import { useApi } from '../../hooks/useApi'
import { DispatchStateDto } from './entities/DispatchStateDto'
import { ActionDispatchStateDto } from '../ActionDispatchState/DTO/ActionDispatchStateDto'
import { DispatchStateActions } from './DispatchStateActions/DispatchStateActions'
import { get } from './api'
import { getList } from '../ActionDispatchState/api'
import { ApiResponse } from '../../typings/ApiResponse'


import { EditForm } from './forms/EditForm'

interface Props {

}

export const DispatchStateEdit: FC<Props> = () => {
    const { id } = useParams<{ id: string }>()
    const dispatchStateApi = useApi<DispatchStateDto>()
    const actionDispatchStateApi = useApi<ApiResponse<ActionDispatchStateDto>>()

    const fetchActionDispatchState = () => {
        if (dispatchStateApi.state.data) {
            actionDispatchStateApi.callApi(getList())
        }
    }

    useEffect(() => {
        dispatchStateApi.callApi(get(id))
    }, [id])

    useEffect(() => {
        fetchActionDispatchState()
    }, [dispatchStateApi.state])
 

    return (
        <StandardPage pageTitle='Estado Homologado' backAction={true}>
            <State state={dispatchStateApi.state} loadingMessage='Cargando Estado'>
                {
                    dispatchStateApi.state.data &&
                        <EditForm dispatchState={dispatchStateApi.state.data} />
                }
            </State>
            {
                dispatchStateApi.state.data &&
                <State state={actionDispatchStateApi.state} loadingMessage='Cargando Acciones'>
                    <DispatchStateActions 
                        data={actionDispatchStateApi.state.data?.items}
                        dispatchState={dispatchStateApi.state.data}
                    />
                </State>
            }
        </StandardPage>
    )
}