import React, { FC } from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

interface Props
{
  title : string
  dialogText : string
  dialogOpen : boolean
  onOkAction?: () => void
  onCancelAction?: () => void
}

export const AlertDialog: FC<Props> = ({
  title,
  dialogText,
  dialogOpen,
  onOkAction,
  onCancelAction

}) =>
 {  

  return (
    <div>
    
      <Dialog
        open={dialogOpen }
        onClose={onCancelAction}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"        

      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onOkAction && onOkAction() }  autoFocus>Aceptar</Button>
          <Button onClick={onCancelAction}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}