import React, { FC } from 'react'
import { useFormik } from 'formik'
import { createStyles, makeStyles } from '@material-ui/core'

import { CourierServiceDto } from './DTO/CourierServiceDto'
import { Modal } from '../../components/_EOS/Modal'
import { CourierServiceForm } from './forms/CourierServiceForm'
import { useApi } from '../../hooks/useApi'
import {  update } from './api'
import { Status } from '../../hooks/useFetchState'
import { courierServiceSchema } from './forms/Schema'



interface Props {
    id : number
    open: boolean 
    courierService : CourierServiceDto   
    onClose: (success?: boolean) => void
}

export const CourierServiceEdit: FC<Props> = ({
    courierService,
    open,
    onClose,
}) => {
    const { state, callApi } = useApi()

    const styles = useStyles()

    
    const {      
        values,
        errors,
        handleChange,
        resetForm,
        submitForm
    } = useFormik({
        enableReinitialize : true,
        initialValues: courierService,
        onSubmit: values => {
            sendData(values)
        },
        validateOnChange: true,
        validationSchema: courierServiceSchema
    })   
    
    const sendData = (courierService: CourierServiceDto) => {
        callApi(update(courierService))
            .then(() => {
                resetForm()
                onClose(true)
            })
    }

    const handleSuccessButton = () => {
        submitForm()
    }

    const handleClose = () => {
        resetForm()
        onClose()
    }

    return (
        <Modal
            open={open}
            title='Editar Servicio'
            loading={state.status === Status.Loading}
            error={state.status === Status.Error ? state.error : undefined}
            cancelButton={{
                title: 'Cancelar',
                action: handleClose
            }}
            successButton={{
                title: 'Actualizar',
                action: handleSuccessButton
            }}>

            <div className={styles.container}>
                <CourierServiceForm values={values} errors={errors} handleChange={handleChange}/>
            </div>

        </Modal>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        input: {
            marginBottom: 20,
        }
    })
)
