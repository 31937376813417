import React, { FC } from 'react'

import { useStyles } from './styles'

interface Props {

}

export const CardItem: FC<Props> = ({
    children
}) => {
    const styles = useStyles()

    return (
        <div className={styles.container}>
            { children }
        </div>
    )
}