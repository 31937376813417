import { FC, useEffect } from 'react'
import axios from 'axios'
import { getErrorMessage } from './errorMessages' 

export const AxiosConfig: FC = () => {
    useEffect(() => {
        axios.interceptors.response.use((config) => {
            return config
        }, (error) => {
            if(error.response) {
                return Promise.reject(getErrorMessage(error.response.status))
            } 
            else if (error.request) {
                return Promise.reject(getErrorMessage(0))
            }
            return Promise.reject(getErrorMessage(0))
        })
    }, [])

    return null
}