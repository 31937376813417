import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { StandardPage } from '../../components/_EOS/StandardPage'
import { State } from '../../components/_EOS/State'
import { useApi } from '../../hooks/useApi'
import { CourierDto } from './DTO/CourierDto'
import { get } from './api'
import { EditForm } from './Forms/EditForm'
import {TabCourier} from './Forms/TabCourier'


interface Props {

}

export const CourierEdit: FC<Props> = () => {
    const { id } = useParams<{ id: string }>()
    const courierApi = useApi<CourierDto>()
  
    useEffect(() => {
        courierApi.callApi(get(id))
    }, [id])

    
    return (
        <StandardPage pageTitle='Courier' backAction={true}>
            <State state={courierApi.state} loadingMessage='Cargando información del courier'>
                {
                    courierApi.state.data &&
                        <EditForm courier={courierApi.state.data} />
                }
            </State> 
            {
                courierApi.state.data &&
                <State state={courierApi.state} loadingMessage='Cargando Detalles del courier'>
                    <TabCourier tabValue={0} id={courierApi.state.data.courierId}></TabCourier>
                </State>
            }           
        </StandardPage>
    )
}