import axios from 'axios'

import config from '../../../config'
import { PosCreateDto } from '../entities/PosCreateDto'
import { PaymentMethodPosCreateDto } from '../entities/PaymentMethodPosCreateDto'
import { PaymentMethodPosUpdateDto } from '../entities/PaymentMethodPosUpdateDto'
import { PosDto } from '../entities/PosDto'

export const getList = (name?: string, pageIndex = 0, pageSize = 10) => {
    const url = new URL('pos', config.apis.pos)
    if (name) {
        url.searchParams.set('name', name)
    }
    url.searchParams.set('pageIndex', pageIndex.toString())
    url.searchParams.set('pageSize', pageSize.toString())
    return axios.get(url.toString())
}

export const get = (id: string) => {
    const url = new URL(`pos/${id}`, config.apis.pos)
    return axios.get(url.toString())
}

export const create = (pos: PosCreateDto) => {
    const url = new URL('pos', config.apis.pos)
    return axios.post(url.toString(), pos)
}

export const update = (pos: PosDto) => {
    const url = new URL(`pos/${pos.posId}`, config.apis.pos)
    return axios.put(url.toString(), pos)
}


export const getPaymentMethodPos = (posCode: string) => {
    const url = new URL(`paymentmethodpos/${posCode}`, config.apis.pos)
    return axios.get(url.toString())
}

export const createPaymentMethodPos = (paymentMethodPos: PaymentMethodPosCreateDto) => {
    const url = new URL(`paymentmethodpos`, config.apis.pos)
    return axios.post(url.toString(), paymentMethodPos)
}

export const updatePaymentMethodPos = (posCode: string, paymentMethodCode: string, paymentMethodPos: PaymentMethodPosUpdateDto) => {
    const url = new URL(`paymentmethodpos/${posCode}/${paymentMethodCode}`, config.apis.pos)
    return axios.put(url.toString(), paymentMethodPos)
}