import React, { FC, Fragment } from 'react'
import { CircularProgress, Typography } from '@material-ui/core'

import { useStyles } from './styles'
import { State as IState, Status } from '../../../hooks/useFetchState'

interface Props {
    state: IState
    loadingMessage?: string
}

export const State: FC<Props> = ({
    children,
    state,
    loadingMessage
}) => {
    const styles = useStyles()

    return (
        <Fragment>
            {
                (state.status === Status.Loading || state.status === Status.Idle) &&
                <div className={styles.container}>
                    <CircularProgress color='primary'/>
                    {
                        loadingMessage &&
                        <Typography component='span' >{loadingMessage}</Typography>
                    }
                </div>
            }

            {
                state.status === Status.Error &&
                <div className={styles.container}>
                    <Typography component='span' >{state.error}</Typography>
                </div>
            }

            {
                state.status === Status.Success && 
                children
            }
        </Fragment>
    )
}