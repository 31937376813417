import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { StandardPage } from '../../components/_EOS/StandardPage'
import { State } from '../../components/_EOS/State'
import { CourierTable } from './CourierTable'
import { useApi } from '../../hooks/useApi'
import { usePagination } from '../../hooks/usePagination'
import { useNotification } from '../../hooks/useNotification'
import { CourierCreate } from './CourierCreate'
import { CourierSearch } from './CourierSearch'
import { ApiResponse } from '../../typings/ApiResponse'
import { CourierDto } from './DTO/CourierDto'
import { AlertDialog } from '../../components/Dialog/AlertDialog'
import { getList, remove } from './api'
import { SnackBar } from '../../components/Notification/Snackbar'
interface Props {

}

export const CourierList: FC<Props> = () => {
    const history = useHistory()
    const { path } = useRouteMatch()
    const { state, callApi } = useApi<ApiResponse<CourierDto>>()
    const pagination = usePagination(5)
    const notification = useNotification()
    const [createModalOpen, setCreateModalOpen] = useState(false)

    const [courierName, setCourierName] = useState('')
    const [courierCode, setCourierCode] = useState('')
    const [currentCourierId, setCurrentCourierId] = useState(0)
    const [openDialog, setOpenDialog] = useState(false)

    const fetchData = (code?: string, name?: string, onlyActive = false, page?: number, pageSize?: number) => {
        const pageParameter = page !== undefined ? page : pagination.page
        const pageSizeParameter = pageSize !== undefined ? pageSize : pagination.pageSize
        callApi(getList(code, name, onlyActive, pageParameter, pageSizeParameter))
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleActionButton = useCallback(() => {
        setCreateModalOpen(true)
    }, [])

    const handleEdit = (id: number) => {
        history.push(`${path}/edit/${id}`)
    }

    const handleDelete = (id: number) => {
        setCurrentCourierId(id)
        setOpenDialog(true)
    }

    const okDialogAction = () => {
        setOpenDialog(false)
        remove(currentCourierId.toString())
            .then(() => {
                fetchData(courierCode, courierName, false, pagination.page, pagination.pageSize)
                notification.success("El registro se eliminó del sistema")
            })
            .catch(() => {
                notification.error("No se puede eliminar el registro del courier en el sistema. Revise las dependecias asociadas")
            })
    }

    const cancelDialogAction = () => {
        setOpenDialog(false)
    }

    const handleModalClose = (success?: boolean) => {
        if (success) {
            fetchData()            
            setCourierName('')
            setCourierCode('')
            notification.success("El registro se ha realizado de manera exitosa")
        }
        setCreateModalOpen(false)
    }

    const handlePaginationChange = (page: number, pageSize: number) => {

        fetchData(courierCode, courierName, false, page, pageSize)
        pagination.setPagination(page, pageSize)
    }

    const handleSearch = () => {
        fetchData(courierCode, courierName, false, 0, 5)
        pagination.reset()
    }

    const handleReset = () => {
        fetchData()
        pagination.reset()
        setCourierName('')
        setCourierCode('')
    }


    return (
        <Fragment>
            <StandardPage pageTitle='Administrar Couriers' buttonTitle='Crear' buttonAction={handleActionButton}>
                <CourierSearch
                    courierCode={courierCode}
                    onCourierCodeChange={setCourierCode}
                    courierName={courierName}
                    onCourierNameChange={setCourierName}
                    onSearch={handleSearch}
                    onReset={handleReset}
                />
                <State state={state} loadingMessage='Cargando Couriers'>
                    <CourierTable
                        data={state.data?.items}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        pagination={{
                            totalItem: state.data?.totalItems || 0,
                            page: pagination.page,
                            pageSize: pagination.pageSize,
                        }}
                        onPaginationChange={handlePaginationChange}
                    />
                </State>
            </StandardPage>
            <CourierCreate open={createModalOpen} onClose={handleModalClose} />
            <AlertDialog title='MCIM' dialogText='Está seguro de eliminar el courier del sistema?' dialogOpen={openDialog} onOkAction={okDialogAction} onCancelAction={cancelDialogAction}></AlertDialog>
            <SnackBar
                open={notification.snackBarOpen}
                setOpen={notification.handleSnackbar}
                message={notification.snackBarMessage}
                status={notification.snackBarStatus}
            ></SnackBar>
        </Fragment>
    )
}