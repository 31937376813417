import { useState } from 'react'

export const usePagination = (defaultPageSize = 10) => {
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(defaultPageSize)

    const setPagination = (page: number, pageSize: number) => {
        setPage(page)
        setPageSize(pageSize)
    }

    const reset = () => {
        setPage(0)
        setPageSize(defaultPageSize)
    }

    return {
        page,
        pageSize,
        setPagination,
        reset
    }
}
