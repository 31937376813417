import * as yup from 'yup'

const stateNameYup = yup
    .string()
    .required('Este campo es requerido')

const dispatchStateTypeIdYup = yup
    .number()
    .required('Este campo es requerido')

const activeYup = yup
    .string()
    .required('Este campo es requerido')

export const dispatchStateSchema = yup.object().shape({
    stateName: stateNameYup,
    dispatchStateTypeId: dispatchStateTypeIdYup,
    active: activeYup,
})