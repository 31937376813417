import React, { FC, Fragment, useCallback, useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { StandardPage } from '../../components/_EOS/StandardPage'
import { State } from '../../components/_EOS/State'
import { SellerTable } from './SellerTable'
import { SellerSearch } from './SellerSearch'
import { useApi } from '../../hooks/useApi'
import { usePagination } from '../../hooks/usePagination'
import { SellerCreate } from './SellerCreate'
import { ApiResponse } from '../../typings/ApiResponse'
import { SellerDto } from './entities/SellerDto'
import { getList } from './api'

interface Props {

}

export const SellerList: FC<Props> = () => {
    const history = useHistory()
    const { path } = useRouteMatch()
    const { state, callApi } = useApi<ApiResponse<SellerDto>>()
    const pagination = usePagination(5)
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [email, setEmail] = useState('')

    const fetchData = (email?: string, page?: number, pageSize?: number) => {
        const pageParameter = page !== undefined ? page : pagination.page
        const pageSizeParameter = pageSize !== undefined ? pageSize : pagination.pageSize
        callApi(getList(email, pageParameter, pageSizeParameter))
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleActionButton = useCallback(() => {
        setCreateModalOpen(true)
    }, [])

    const handleEdit = (id: number) => {
        history.push(`${path}/edit/${id}`)
    }

    const handleModalClose = (success?: boolean) => {
        if (success) {
            fetchData()
            pagination.reset()
            setEmail('')
        }
        setCreateModalOpen(false)
    }

    const handlePaginationChange = (page: number, pageSize: number) => {
        fetchData(email, page, pageSize)
        pagination.setPagination(page, pageSize)
    }

    const handleSearch = () => {
        fetchData(email, 0, pagination.pageSize)
        pagination.reset()
    }

    return (
        <Fragment>
            <StandardPage pageTitle='Vendedores' buttonTitle='Crear' buttonAction={handleActionButton}>
                <SellerSearch email={email} onChangeEmail={setEmail} onSearch={handleSearch}/>
                <State state={state} loadingMessage='Cargando Vendedores'>
                    <SellerTable 
                        data={state.data?.items}
                        onEdit={handleEdit}
                        pagination={{
                            totalItem: state.data?.totalItems || 0,
                            page: pagination.page,
                            pageSize: pagination.pageSize,
                        }}
                        onPaginationChange={handlePaginationChange}
                    />
                </State>
            </StandardPage>
            <SellerCreate open={createModalOpen} onClose={handleModalClose} />
        </Fragment>
    )
}