import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core'
import { CourierParameterTable } from './CourierParameterTable'
import { Status } from '../../hooks/useFetchState'
import { useApi } from '../../hooks/useApi'
import { usePagination } from '../../hooks/usePagination'
import { useNotification } from '../../hooks/useNotification'
import { ApiResponse } from '../../typings/ApiResponse'
import { CourierParameterDto } from './DTO/CourierParameterDto'
import { CourierParameterTypeDto } from './DTO/CourierParameterTypeDto'
import { CourierParameterCreate } from './CourierParameterCreate'
import { CourierParameterEdit } from './CourierParameterEdit'
import { LoadingButton } from '../../components/_EOS/Button/LoadingButton'
import { AlertDialog } from '../../components/Dialog/AlertDialog'
import { SnackBar } from '../../components/Notification/Snackbar'
import { getList, remove, getParameterList } from './api'


interface Props {
    courierId: number
}

export const CourierParameterList: FC<Props> = (
    {
        courierId
    }
) => {

    const styles = useStyles()
    const { state, callApi } = useApi<ApiResponse<CourierParameterDto>>()

    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [updateModalOpen, setUpdateModalOpen] = useState(false)
    const [currentCourierParameter, setCurrentCourierParameter] = useState<CourierParameterDto>({ courierId: 0, courierParameterId: 0, key: '', value: '', type: 0 })
    const [currentCourierParameterId, setCurrentCourierParameterId] = useState(0)
    const [openDialog, setOpenDialog] = useState(false)

    const pagination = usePagination(5)
    const notification = useNotification()

    const parameterTypeApi = useApi<CourierParameterTypeDto[]>()


    const fetchData = (page?: number, pageSize?: number) => {
        const pageParameter = page !== undefined ? page : pagination.page
        const pageSizeParameter = pageSize !== undefined ? pageSize : pagination.pageSize
        callApi(getList(courierId, pageParameter, pageSizeParameter))
    }

    useEffect(() => {
        parameterTypeApi.callApi(getParameterList())
        fetchData()
    }, [])

    const handlePaginationChange = (page: number, pageSize: number) => {
        fetchData(page, pageSize)
        pagination.setPagination(page, pageSize)
    }


    const handleActionButton = useCallback(() => {
        setCreateModalOpen(true)
    }, [])

    const handleActionEditButton = useCallback((courierParameter: CourierParameterDto) => {

        setUpdateModalOpen(true)
        setCurrentCourierParameter(courierParameter)
    }, [])

    const handleDeleteAction = (id: number) => {
        setCurrentCourierParameterId(id)
        setOpenDialog(true)
    }

    const okDialogAction = () => {
        setOpenDialog(false)
        remove(currentCourierParameterId.toString())
            .then(() => {
                fetchData(pagination.page, pagination.pageSize)
            })
            .catch(() => {
                notification.notify("No se puede eliminar el registro del parámetro del courier en el sistema. Revise las dependecias asociadas", "error")
            })


    }



    const cancelDialogAction = () => {
        setOpenDialog(false)
    }

    const handleModalClose = (success?: boolean) => {
        if (success) {
            fetchData()
            pagination.reset()
        }
        setCreateModalOpen(false)
        setUpdateModalOpen(false)
    }

    return (
        <Fragment>
            <div className={styles.buttonContainer}>
                <LoadingButton loading={state.status === Status.Loading} onClick={handleActionButton}>
                    Agregar Parámetro
                </LoadingButton>
            </div>
            <CourierParameterTable
                data={state.data?.items}
                buttonText='Configuración'
                info=''
                title=''
                buttonEditAction={handleActionEditButton}
                buttonDeleteAction={handleDeleteAction}
                pagination={{
                    totalItem: state.data?.totalItems || 0,
                    page: pagination.page,
                    pageSize: pagination.pageSize,
                }}
                onPaginationChange={handlePaginationChange}
            />
            <CourierParameterCreate id={courierId} open={createModalOpen} onClose={handleModalClose} parameterTypes={parameterTypeApi.state.data} />
            <CourierParameterEdit id={courierId} courierParameter={currentCourierParameter} open={updateModalOpen} onClose={handleModalClose} parameterTypes={parameterTypeApi.state.data} />
            <AlertDialog title='MCIM' dialogText='Está seguro de eliminar el parámetro del courier del sistema?' dialogOpen={openDialog} onOkAction={okDialogAction} onCancelAction={cancelDialogAction}></AlertDialog>
            <SnackBar
                open={notification.snackBarOpen}
                setOpen={notification.handleSnackbar}
                message={notification.snackBarMessage}
                status={notification.snackBarStatus}
            ></SnackBar>

        </Fragment>
    )
}

const useStyles = makeStyles(
    createStyles({

        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
        }
    })
)