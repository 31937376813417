import * as yup from 'yup'

const actionIdYup = yup
    .number()
    .min(1, 'Debe ingresar un valor mayor a 1')
    .max(20, 'Debe ingresar un valor menor a 20')    
    .default(1)
    .required('Este campo es requerido')

const actionNameYup = yup
    .string()
    .matches(/^[aA-zZ]+$/, "Valor ingresado no válido")
    .required('Este campo es requerido')

    const descriptionYup = yup
    .string()
    .required('Este campo es requerido')

const activeYup = yup
    .string()
    .required('Este campo es requerido')

export const ActionDispatchStateSchema = yup.object().shape({
    actionId: actionIdYup,
    actionName: actionNameYup, 
    description: descriptionYup, 
    active: activeYup,
})