import * as yup from 'yup'

const codeYup = yup
    .string()
    .required('Este campo es requerido')

const nameYup = yup
    .string()
    .required('Este campo es requerido')


const activeYup = yup    
    .string()
    .required('Este campo es requerido')

export const courierServiceSchema = yup.object().shape({
    serviceCode: codeYup,
    serviceName: nameYup, 
    active: activeYup,
})