import React, { FC, Fragment } from 'react'

import { useStyles } from './styles'

interface Props {

}

export const StackLayout: FC<Props> = ({
    children
}) => {
    const styles = useStyles()

    return (
        <Fragment>
            {
                React.Children.map(children, (child) => {
                    return (
                        <div className={styles.item}>
                            {child}
                        </div>
                    )
                })
            }
        </Fragment>
    )
}