import React, { FC } from 'react'
import { useFormik } from 'formik'
import { SellerDto } from '../entities/SellerDto'
import { useApi } from '../../../hooks/useApi'
import { update } from '../api'
import { sellerSchema } from './schema'
import { LoadingButton } from '../../../components/_EOS/Button/LoadingButton'

import { useHistory } from 'react-router-dom'
import {
    Container,
    TextField,
    Switch,
    FormControlLabel,
    makeStyles,
    createStyles
} from '@material-ui/core'
import { Status } from '../../../hooks/useFetchState'

interface Props {
    seller: SellerDto
}
export const EditForm: FC<Props> = ({
    seller
}) => {
    const history = useHistory()
    const { state, callApi } = useApi()
    const styles = useStyles()

    const {
        values,
        errors,
        handleChange,
        submitForm
    } = useFormik({
        initialValues: seller,
        onSubmit: values => {
            sendData(values)
        },
        validationSchema: sellerSchema,
        validateOnChange: true
    })

    const handleSubmitForm = () => {
        submitForm()
    }

    const sendData = (seller: SellerDto) => {
        callApi(update(seller))
            .then(() => {
                history.goBack()
            })
    }
    
    return (
        <Container className={styles.editContainer}>
            <TextField 
                className={styles.editInput} 
                label='Rut' 
                variant='standard' 
                value={values.dni} 
                onChange={handleChange('dni')}
                error={ !!errors.dni }
                helperText={ errors.dni } 
            />
            <TextField 
                className={styles.editInput} 
                label='Nombre' 
                variant='standard' 
                value={values.name} 
                onChange={handleChange('name')}
                error={errors.name !== undefined}
                helperText={errors.name}
             />
            <TextField 
                className={styles.editInput} 
                label='Email' 
                variant='standard' 
                value={values.email} onChange={handleChange('email')} 
                error={errors.email !== undefined}
                helperText={errors.email}
            />
            <FormControlLabel className={styles.editInput} control={<Switch checked={values.active} onChange={handleChange('active')} />} label='Activo' />
            <div className={styles.buttonContainer}>
                <LoadingButton loading={state.status === Status.Loading} onClick={handleSubmitForm}>
                    Editar
                </LoadingButton>
            </div>
        </Container>
    )
}


const useStyles = makeStyles(
    createStyles({
        editContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: 10,
        },
        editInput: {
            marginBottom: 30,
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
        }
    })
)