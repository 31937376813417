/**
 * Obtiene mensajes de error dependiendo de http status code
 */

 interface ErrorMessages {
    [error: number]: string
}

const errorMessages: ErrorMessages = {
    0: 'Error de red.',
    400: 'Los parámetros no son correctos, intenta nuevamente.',
    401: 'No estás autorizado para realizar esta acción.',
    403: 'No tienes los permisos suficiente para realizar esta acción.',
    404: 'No se han encontrado resultados.',
    503: 'El servicio no se encuentra disponible.',
    500: 'Error interno.',
}

export const getErrorMessage = (statusCode: number | undefined) => {
    if (statusCode === undefined) {
        return 'Error desconocido.'
    }

    return errorMessages[statusCode] || errorMessages[500]
}