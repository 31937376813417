import React, { FC } from 'react'
import { Grid, TextField, FormControl, InputLabel, FormControlLabel, Switch, FormHelperText } from '@material-ui/core'

import { LocationProvider, SelectLocation } from '../../../components/Location'
import { FormikErrors } from 'formik'

import { StoreDto } from '../entities/StoreDto'

interface Props {
    values: any
    errors: FormikErrors<StoreDto>
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    }
}

export const StoreForm: FC<Props> = ({
    values,
    errors,
    handleChange
}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <TextField 
                    label="Código" 
                    value={values.code} 
                    onChange={handleChange('code')} 
                    error={ !!errors.code }
                    helperText={ errors.code }
                    fullWidth
                />
            </Grid>
            <Grid item xs={9}>
                <TextField 
                    label="Nombre de la tienda" 
                    value={values.name} 
                    onChange={handleChange('name')} 
                    error={ !!errors.name }
                    helperText={ errors.name }
                    fullWidth
                />
            </Grid>

            <Grid item xs={9}>
                <TextField 
                    label="Calle" 
                    value={values.streetName} 
                    onChange={handleChange('streetName')} 
                    error={ !!errors.streetName }
                    helperText={ errors.streetName }
                    fullWidth
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label="Número" 
                    value={values.streetNumber} 
                    onChange={handleChange('streetNumber')} 
                    error={ !!errors.streetNumber }
                    helperText={ errors.streetNumber }
                    fullWidth
                />
            </Grid>

            <LocationProvider selectedStateName={values.state} selectedNeighborhoodName={values.neighborhood}>
                <Grid item xs={6}>
                    <FormControl error={ !!errors.state } fullWidth>
                        <InputLabel>Seleccione una región</InputLabel>
                        <SelectLocation type='state' value={values.state.toLowerCase() || ''} onChange={(event: any) => {
                            handleChange('state')(event)
                            handleChange('neighborhood')('')
                        }}
                        />
                        {
                            !!errors.state && <FormHelperText>{ errors.state }</FormHelperText>
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl error={ !!errors.neighborhood } fullWidth>
                        <InputLabel>Seleccione una comuna</InputLabel>
                        <SelectLocation type='neighborhood' value={values.neighborhood.toLowerCase() || ''} onChange={handleChange('neighborhood')} />
                        {
                            !!errors.neighborhood && <FormHelperText>{ errors.neighborhood }</FormHelperText>
                        }
                    </FormControl>
                </Grid>
            </LocationProvider>

            <Grid item xs={6}>
                <TextField 
                    label="Latitud" 
                    type='number' 
                    value={values.latitude} 
                    onChange={handleChange('latitude')} 
                    error={ !!errors.latitude }
                    helperText={ errors.latitude }
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField 
                    label="Longitud" 
                    type='number' 
                    value={values.longitude} 
                    onChange={handleChange('longitude')} 
                    error={ !!errors.longitude }
                    helperText={ errors.longitude }
                    fullWidth
                />
            </Grid>

            <Grid item xs={12}>
                <TextField 
                    label="Referencia de dirección" value={values.reference} onChange={handleChange('reference')} fullWidth/>
            </Grid>
            
            <Grid item xs={12}>
                <FormControlLabel control={<Switch checked={values.active} onChange={handleChange('active')} />} label='Activo'/>
            </Grid>
        </Grid>
    )
}