/* 
    Copyright 2022-06-23 por Cristian Soto Ecomsur Holding S.A ©
    Detalle: Se agrega a EOS mantenedor de estados pedidos POS
*/

import axios from 'axios'

import { OrderStateDto } from '../entities/OrderStateDto'
import config from '../../../config'

export const getList = () => {
    const url = new URL('state', config.apis.pos)
    console.log(url)
    return axios.get(url.toString())
}
export const get = (id: string) => {
    const url = new URL(`state/${id}`, config.apis.pos)
    return axios.get(url.toString())
}

export const update = (orderState: OrderStateDto) => {
    const url = new URL(`state/${orderState.orderStateId}`, config.apis.pos)
    return axios.put(url.toString(), orderState)
}