import React, { FC } from 'react'
import { Typography, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { StackLayout } from '../StackLayout'
import { CardItem } from '../CardItem'
import { useStyles } from './styles'

interface Props {
    pageTitle: string
    buttonTitle?: string
    backAction?: boolean
    buttonAction?: () => void
}

export const StandardPage: FC<Props> = ({
    children,
    pageTitle,
    buttonTitle,
    backAction,
    buttonAction
}) => {
    const history = useHistory()
    const styles = useStyles()

    return (
        <StackLayout>
            <div className={styles.container}>
                <Typography variant='h4' component='h1' >{pageTitle}</Typography>
                {
                    buttonTitle && buttonAction &&
                    <Button variant='contained' color='primary' onClick={buttonAction}>{buttonTitle}</Button>
                }
                {
                    backAction && <Button variant='text' onClick={() => history.goBack()}>Volver</Button>
                }
            </div>

            {
                React.Children.map(children, (child) => {
                    if (child === null) return null

                    return (
                        <CardItem>
                            { child }
                        </CardItem> 
                    )
                })
            }

        </StackLayout>
    )
}