/* 
    Copyright 2022-06-23 por Cristian Soto Ecomsur Holding S.A ©
    Detalle: Se agrega a EOS mantenedor de estados pedidos POS
*/

import React, { FC } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { OrderStatesList } from './OrderStateList'
import { OrderStateEdit } from './OrderStateEdit'

interface Props {

}

export const OrderStatesPage: FC<Props> = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path} component={OrderStatesList}/>
            <Route exact path={`${path}/edit/:id`} component={OrderStateEdit}/>
        </Switch>
    )
}