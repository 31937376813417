import React, { FC } from 'react'
import { useFormik } from 'formik'
import { createStyles, makeStyles } from '@material-ui/core'

import { ActionDispatchStateDto } from './DTO/ActionDispatchStateDto'
import { Modal } from '../../components/_EOS/Modal'
import { ActionDispatchStateForm } from './forms/ActionDispatchStateForm'
import { useApi } from '../../hooks/useApi'
import { create } from './api'
import { Status } from '../../hooks/useFetchState'
import { ActionDispatchStateSchema } from './forms/Schema'
interface Props {
    open: boolean
    onClose: (success?: boolean) => void
}

export const ActionDispatchStateCreate: FC<Props> = ({
    open,
    onClose,
}) => {
    const { state, callApi } = useApi()

    const styles = useStyles()

    const {
        values,
        errors,
        handleChange,
        resetForm,
        submitForm
    } = useFormik({
        initialValues: {
            actionId: 0,
            actionName: '',
            description: '',
            active: true,
        },
        onSubmit: values => {
            sendData(values)
        },
        validateOnChange: true,
        validationSchema: ActionDispatchStateSchema
    })

    const sendData = (actionDispatchState: ActionDispatchStateDto) => {
        callApi(create(actionDispatchState))
            .then(() => {
                resetForm()
                onClose(true)
            })
    }

    const handleSuccessButton = () => {
        submitForm()
    }

    const handleClose = () => {
        resetForm()
        onClose()
    }

    return (
        <Modal
            open={open}
            title='Crear Acción'
            loading={state.status === Status.Loading}
            error={state.status === Status.Error ? state.error : undefined}
            cancelButton={{
                title: 'Cancelar',
                action: handleClose
            }}
            successButton={{
                title: 'Guardar',
                action: handleSuccessButton
            }}>

            <div className={styles.container}>
                <ActionDispatchStateForm values={values} errors={errors} handleChange={handleChange}/>
            </div>

        </Modal>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        input: {
            marginBottom: 20,
        }
    })
)
