import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core'
import { CourierDispatchStateTable } from './CourierDispatchStateTable'
import { Status } from '../../hooks/useFetchState'
import { useApi } from '../../hooks/useApi'
import { usePagination } from '../../hooks/usePagination'
import { useNotification } from '../../hooks/useNotification'
import { ApiResponse } from '../../typings/ApiResponse'
import { CourierDispatchStateDto } from './DTO/CourierDispatchStateDto'
import { CourierDispatchStateCreate } from './CourierDispatchStateCreate'
import { CourierDispatchStateEdit } from './CourierDispatchStateEdit'
import { LoadingButton } from '../../components/_EOS/Button/LoadingButton'
import { AlertDialog } from '../../components/Dialog/AlertDialog'
import { SnackBar } from '../../components/Notification/Snackbar'
import { getList, remove } from './api'
import { getAllList, getTypeAllList } from '../DispatchStates/api'
import { DispatchStateDto } from "../DispatchStates/entities/DispatchStateDto"
import { DispatchStateTypeDto } from "../DispatchStates/entities/DispatchStateTypeDto"


interface Props {
    courierId: number
}

export const CourierDispatchStateList: FC<Props> = (
    {
        courierId
    }
) => {

    const styles = useStyles()
    const { state, callApi } = useApi<ApiResponse<CourierDispatchStateDto>>()
    const dispatchStateApi = useApi<DispatchStateDto[]>()
    const dispatchStateTypeApi = useApi<DispatchStateTypeDto[]>()
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [updateModalOpen, setUpdateModalOpen] = useState(false)
    const [currentCourierDispatchState, setCurrentCourierDispatchState] = useState<CourierDispatchStateDto>({
        courierId: 0,
        courierDispatchStateId: 0,
        couierStateCode: '',
        couierState: '',
        dispatchStateTypeId: 0,
        dispatchStateId: 0,
        dispatchStateName: '',
        active: false
    })
    const [currentCourierDispatchStateId, setCurrentCourierDispatchStateId] = useState(0)
    const [openDialog, setOpenDialog] = useState(false)

    const pagination = usePagination(5)
    const notification = useNotification()


    const fetchData = (page?: number, pageSize?: number) => {
        const pageParameter = page !== undefined ? page : pagination.page
        const pageSizeParameter = pageSize !== undefined ? pageSize : pagination.pageSize
        callApi(getList(courierId, pageParameter, pageSizeParameter))

    }

    useEffect(() => {
        dispatchStateApi.callApi(getAllList())
        dispatchStateTypeApi.callApi(getTypeAllList())

        fetchData()
    }, [])

    const handlePaginationChange = (page: number, pageSize: number) => {
        fetchData(page, pageSize)
        pagination.setPagination(page, pageSize)
    }


    const handleActionButton = useCallback(() => {
        setCreateModalOpen(true)
    }, [])

    const handleActionEditButton = useCallback((courierDispatchState: CourierDispatchStateDto) => {

        setUpdateModalOpen(true)
        setCurrentCourierDispatchState(courierDispatchState)
    }, [])

    const handleDeleteAction = (id: number) => {
        setCurrentCourierDispatchStateId(id)
        setOpenDialog(true)
    }


    const okDialogAction = () => {
        setOpenDialog(false)
        remove(courierId.toString(), currentCourierDispatchStateId.toString())
            .then(() => {
                fetchData(pagination.page, pagination.pageSize)
            })
            .catch(() => {
                notification.notify("No se puede eliminar el registro del estado de despacho del courier en el sistema. Revise las dependecias asociadas", "error")
            })

    }

    const cancelDialogAction = () => {
        setOpenDialog(false)
    }

    const handleModalClose = (success?: boolean) => {
        if (success) {
            fetchData(pagination.page, pagination.pageSize)
            //pagination.setPagination(pageParameter, pageSizeParameter)         
        }
        setCreateModalOpen(false)
        setUpdateModalOpen(false)
    }

    return (
        <Fragment>
            <div className={styles.buttonContainer}>
                <LoadingButton loading={state.status === Status.Loading} onClick={handleActionButton}>
                    Agregar Estado
                </LoadingButton>
            </div>
            <CourierDispatchStateTable
                data={state.data?.items}
                buttonEditAction={handleActionEditButton}
                buttonDeleteAction={handleDeleteAction}
                pagination={{
                    totalItem: state.data?.totalItems || 0,
                    page: pagination.page,
                    pageSize: pagination.pageSize,
                }}
                onPaginationChange={handlePaginationChange}
            />
            <CourierDispatchStateCreate id={courierId} open={createModalOpen} onClose={handleModalClose} dispatchStateType={dispatchStateTypeApi.state.data} dispatchState={dispatchStateApi.state.data} />
            <CourierDispatchStateEdit id={courierId} courierDispatchState={currentCourierDispatchState} open={updateModalOpen} onClose={handleModalClose} dispatchStateType={dispatchStateTypeApi.state.data} dispatchState={dispatchStateApi.state.data} />
            <AlertDialog title='MCIM' dialogText='Está seguro de eliminar el estado del courier en el sistema?' dialogOpen={openDialog} onOkAction={okDialogAction} onCancelAction={cancelDialogAction}></AlertDialog>
            <SnackBar
                open={notification.snackBarOpen}
                setOpen={notification.handleSnackbar}
                message={notification.snackBarMessage}
                status={notification.snackBarStatus}
            ></SnackBar>

        </Fragment>
    )
}

const useStyles = makeStyles(
    createStyles({

        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
        }
    })
)