import React, { FC, useEffect } from 'react'
import { useFormik } from 'formik'
import { Select, MenuItem, createStyles, makeStyles, FormControl, InputLabel, FormControlLabel, Switch, TextField } from '@material-ui/core'

import { useApi } from '../../../../../hooks/useApi'
import { Countries, States, Item } from '../../../entities/MercadoLibre'
import { getCountry, getState } from '../../../api/mercadoLibre'

export interface IMercadoPagoForm {
    active: boolean
    StateName: string // Respetar case en mayusculas
    CityName: string // Respetar case en mayusculas
    ExternalReference: string
}

export const initialValues: IMercadoPagoForm = {
    active: false,
    StateName: '',
    CityName: '',
    ExternalReference: '',
}

interface Props extends ReturnType<typeof useFormik> {
    
}

const getId = (data: Item[], name: string) => {
    const found = data.find((item) => item.name === name)
    return found?.id || ''
}

export const MercadoPagoForm: FC<Props> = ({
    values,
    handleChange
}) => {
    const citiesApi = useApi<Countries>()
    const statesApi = useApi<States>()

    const styles = useStyles()

    useEffect(() => {
        citiesApi.callApi(getCountry('CL'))
            .then((data) => {
                if(values.StateName) {
                    statesApi.callApi(getState(getId(data.states, values.StateName)))
                }
            })
    }, [])

    const handleStateChange = (event: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
        const stateName = event.target.value as string
        statesApi.callApi(getState(getId(citiesApi.state.data?.states || [], stateName)))
        handleChange('StateName')(stateName)
    }

    const handleCityChange = (event: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
        const value = event.target.value as string
        handleChange('CityName')(value)
    }

    return (
        <div className={styles.container}>
            <FormControlLabel
                label={values.active ? 'Activado' : 'Desactivado'}
                control={
                    <Switch checked={values.active} onChange={handleChange('active')}/>
                }
            />

            <FormControl>
                <InputLabel>Seleccione una región</InputLabel>
                <Select className={styles.input} value={values.StateName || ''} onChange={handleStateChange}>
                    <MenuItem value=''>Seleccione una región</MenuItem>
                    {
                        citiesApi.state.data &&
                        citiesApi.state.data.states.map((state) => <MenuItem value={state.name}>{state.name}</MenuItem>)
                    }
                </Select>
            </FormControl>

            <FormControl>
                <InputLabel>Seleccione una comuna</InputLabel>
                <Select disabled={!values.StateName} className={styles.input} value={values.CityName || ''} onChange={handleCityChange}>
                    <MenuItem value=''>Seleccione una comuna</MenuItem>
                    {
                        statesApi.state.data &&
                        statesApi.state.data.cities.map((city) => <MenuItem value={city.name}>{city.name}</MenuItem>)
                    }
                </Select>
            </FormControl>

            <TextField contentEditable={false} label="ExternalReference" value={values.ExternalReference} />
        </div>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        input: {
            marginBottom: 20,
        }
    })
)