import React, { FC } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { ActionDispatchStateList } from './ActionDispatchStateList'
import { ActionDispatchStateEdit } from './ActionDispatchStateEdit'

interface Props {

}

export const ActionDispatchStatePage: FC<Props> = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path} component={ActionDispatchStateList}/>
            <Route exact path={`${path}/edit/:id`} component={ActionDispatchStateEdit}/>
        </Switch>
    )
}