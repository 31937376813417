import * as yup from 'yup'

const codeYup = yup
    .string()
    .required('Este campo es requerido')

const nameYup = yup
    .string()
    .required('Este campo es requerido')



const activeYup = yup
    .string()
    .required('Este campo es requerido')

export const courierSchema = yup.object().shape({
    courierCode: codeYup,
    name: nameYup, 
    active: activeYup,
})