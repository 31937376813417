
import React, { FC } from 'react'
import { useFormik } from 'formik'
import { createStyles, makeStyles } from '@material-ui/core'

import { CourierDispatchStateCreateDto } from './DTO/CourierDispatchStateCreateDto'
import { DispatchStateDto } from  '../DispatchStates/entities/DispatchStateDto'
import { DispatchStateTypeDto } from '../DispatchStates/entities/DispatchStateTypeDto'
import { Modal } from '../../components/_EOS/Modal'
import { CourierDispatchStateForm } from './forms/CourierDispatchStateForm'
import { useApi } from '../../hooks/useApi'
import { create } from './api'
import { Status } from '../../hooks/useFetchState'
import { courierDispatchStateSchema } from './forms/Schema'



interface Props {
    id : number,
    dispatchStateType? : DispatchStateTypeDto[] 
    dispatchState? : DispatchStateDto[]
    open: boolean    
    onClose: (success?: boolean) => void
}

export const CourierDispatchStateCreate: FC<Props> = ({
    id,
    dispatchStateType,
    dispatchState,
    open,
    onClose,
}) => {
    const { state, callApi } = useApi()

    const styles = useStyles()

    const {
        values,
        errors,
        handleChange,
        resetForm,
        submitForm
    } = useFormik({
        initialValues: {          
            courierId:id, 
            courierDispatchStateId: 0, 
            couierStateCode: '', 
            couierState: '', 
            dispatchStateTypeId:0,
            dispatchStateId:0,  
            dispatchStateName: '' , 
            active : false
        },
        onSubmit: values => {
            sendData(values)
        },
        validateOnChange: true,
        validationSchema: courierDispatchStateSchema
    })

    const sendData = (courierDispatchState: CourierDispatchStateCreateDto) => {
        callApi(create(courierDispatchState))
            .then(() => {
                resetForm()
                onClose(true)
            })
    }

    const handleSuccessButton = () => {
        submitForm()
    }

    const handleClose = () => {
        resetForm()
        onClose()
    }

    return (
        <Modal
            open={open}
            title='Crear Estado'
            loading={state.status === Status.Loading}
            error={state.status === Status.Error ? state.error : undefined}
            cancelButton={{
                title: 'Cancelar',
                action: handleClose
            }}
            successButton={{
                title: 'Guardar',
                action: handleSuccessButton
            }}>

            <div className={styles.container}>
                <CourierDispatchStateForm dispatchStateType={dispatchStateType} dispatchState={dispatchState} values={values} errors={errors} handleChange={handleChange}/>
            </div>

        </Modal>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        input: {
            marginBottom: 20,
        }
    })
)
