/* 
    Copyright 2022-06-23 por Cristian Soto Ecomsur Holding S.A ©
    Detalle: Se agrega a EOS mantenedor de estados pedidos POS
*/

import React, { FC } from 'react'
import { useFormik } from 'formik'
import { OrderStateDto } from '../entities/OrderStateDto'
import { useApi } from '../../../hooks/useApi'
import { update } from '../api'
import { sellerSchema } from './schema'
import { LoadingButton } from '../../../components/_EOS/Button/LoadingButton'

import { useHistory } from 'react-router-dom'
import {
    Container,
    TextField,
    Switch,
    FormControlLabel,
    makeStyles,
    createStyles
} from '@material-ui/core'
import { Status } from '../../../hooks/useFetchState'

interface Props {
    orderState: OrderStateDto
}

export const EditForm: FC<Props> = ({
    orderState
}) => {
    const history = useHistory()
    const { state, callApi } = useApi()
    const styles = useStyles()

    const {
        values,
        errors,
        handleChange,
        submitForm
    } = useFormik({
        initialValues: orderState,
        onSubmit: values => {
            sendData(values)
        },
        validationSchema: sellerSchema,
        validateOnChange: true
    })

    const handleSubmitForm = () => {
        submitForm()
    }

    const sendData = (orderState: OrderStateDto) => {
        callApi(update(orderState))
            .then(() => {
                history.goBack()
            })
    }

    return(
        <Container className={styles.editContainer}>
            <TextField 
                className={styles.editInput}
                label='Código'
                variant='standard'
                value={values.code}
                onChange={handleChange('code')}
                disabled={true}
            />
            <TextField 
                className={styles.editInput}
                label='Nombre'
                variant='standard'
                value={values.name}
                onChange={handleChange('name')}
                disabled={true}
            />
            <TextField 
                className={styles.editInput}
                label='Descripción'
                variant='standard'
                value={values.description}
                onChange={handleChange('description')}
                error={errors.description !== undefined}
                helperText={errors.description}
            />
            <FormControlLabel 
                className={styles.editInput}
                control={<Switch
                    checked={values.sendEmail}
                    onChange={handleChange('sendEmail')} />
                } 
                label='¿Envía email?' />
            <div className={styles.buttonContainer}>
                <LoadingButton loading={state.status === Status.Loading} onClick={handleSubmitForm}>
                    Editar
                </LoadingButton>
            </div>
        </Container>
    )
}

const useStyles = makeStyles(
    createStyles({
        editContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: 10,
        },
        editInput: {
            marginBottom: 30,
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
        }
    })
)