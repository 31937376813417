import React, { FC } from 'react'
import { useFormik } from 'formik'

import { SellerCreateDto } from './entities/SellerCreateDto'
import { Modal } from '../../components/_EOS/Modal'
import { CreateForm } from './forms/CreateForm'
import { sellerSchema } from './forms/schema'
import { useApi } from '../../hooks/useApi'
import { create } from './api'
import { Status } from '../../hooks/useFetchState'

interface Props {
    open: boolean
    onClose: (success?: boolean) => void
}

export const SellerCreate: FC<Props> = ({
    open,
    onClose,
}) => {
    const { state, callApi } = useApi()

    const {
        values,
        errors,
        handleChange,
        resetForm,
        submitForm
    } = useFormik({
        initialValues: {
            dni: '',
            name: '',
            email: '',
        },
        onSubmit: values => {
            sendData(values)
        },
        validationSchema: sellerSchema,
        validateOnChange: true
    })

    const sendData = (seller: SellerCreateDto) => {
        callApi(create(seller))
            .then(() => {
                resetForm()
                onClose(true)
            })
    }

    const handleSuccessButton = () => {
        submitForm()
    }

    return (
        <Modal
            open={open}
            title='Crear vendedor'
            loading={state.status === Status.Loading}
            error={state.status === Status.Error ? state.error : undefined}
            cancelButton={{
                title: 'Cancelar',
                action: () => {
                    resetForm()
                    onClose()
                }
            }}
            successButton={{
                title: 'Guardar',
                action: handleSuccessButton,
            }}>

            <CreateForm values={values} errors={errors} onHandleChange={handleChange} />
            
        </Modal>
    )
}