import React, { FC } from 'react'
import { Select, SelectProps, MenuItem } from '@material-ui/core'

import { useLocation } from './LocationProvider'

interface Props extends SelectProps {
    type: 'state' | 'neighborhood'
}

export const SelectLocation: FC<Props> = ({ type, ...selectProps }) => {
    const { stateApi, neighborhoodApi } = useLocation()

    const items: {code: string, name: string}[] | undefined = {
        'state': stateApi.state.data?.items,
        'neighborhood': neighborhoodApi.state.data?.items
    }[type]

    const value = selectProps.value as string

    // Indica si el valor existe dentro del listado
    const isValid = items?.find((item) => item.name.toLowerCase() === value.toLowerCase())

    return (
        <Select defaultValue="" {...selectProps}>
            <MenuItem value="">Seleccione una comuna</MenuItem>
            {
                !isValid && <MenuItem value={value}>{value}</MenuItem>
            }
            {
                items && items.map((item) => <MenuItem key={item.code} value={item.name.toLowerCase()}>{item.name}</MenuItem>)
            }
        </Select>
    )
}
