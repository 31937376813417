/* 
    Copyright 2022-06-23 por Diego Andres Estrada Holding S.A ©
    Detalle: Se agrega a EOS mantenedor de configuraciones internacionales POS
*/

import React, { FC, useEffect } from 'react'
import { makeStyles, createStyles, TextField, InputLabel } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { InternationalCallsigns } from './utils/InternationalCallsigns'
import { Config } from './entities/InternacionalizationInfoDto'

interface Props {
    config: Config,
    values: Config,
    onHandleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    },
}

export const ConfigGeoPolitics: FC<Props> = ({
    config,
    values,
    onHandleChange,
}) => {
    const styles = useStyles()
    
    useEffect(()=>{
        values.internacionalization=config.internacionalization
    },[])

    return (
        <div className={styles.container}>

            <InputLabel id="label-title">Configuracion geopolitica</InputLabel>
            <div className={styles.container1}>
                <TextField variant="outlined" size="small" label="Codigo Pais" defaultValue={config.internacionalization.codeCountry} onChange={onHandleChange('internacionalization.codeCountry')} />
                <Autocomplete
                    id="combo-box-demo"
                    options={InternationalCallsigns}
                    getOptionLabel={(option) => option}
                    style={{ width: '100%' }}
                    defaultValue={config.internacionalization.telephonePrefix}
                    renderInput={(params) => <TextField {...params}
                        label="Prefijo Telefono Preseleccionado"
                        variant="outlined"
                        onSelect={onHandleChange('internacionalization.telephonePrefix')}
                        size='small' />}
                />
                <TextField variant="outlined" size="small" label="Simbolo Moneda Local" defaultValue={config.internacionalization.currencySymbol} onChange={onHandleChange('internacionalization.currencySymbol')} />
            </div>
            <div></div>
            <InputLabel id="label-title">División geopolitica</InputLabel>
            <div className={styles.container2}>
                <TextField size="small" variant="outlined" label="1° nivel" defaultValue={config.internacionalization.levelOneDivision} onChange={onHandleChange('internacionalization.levelOneDivision')} />
                <TextField size="small" variant="outlined" label="2° nivel" defaultValue={config.internacionalization.levelTwoDivision} onChange={onHandleChange('internacionalization.levelTwoDivision')} />
                <TextField size="small" variant="outlined" label="3° nivel" defaultValue={config.internacionalization.levelThreeDivision} onChange={onHandleChange('internacionalization.levelThreeDivision')} />
            </div>
        </div>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'grid',
            gridTemplateRows: '0.7fr 1fr 0.6fr 0.7fr 1fr',
        },
        container1: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            columnGap: '3%'
        },
        container2: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: '3%'
        },
    })
)
