import React, { FC } from 'react'
import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core'


import { FormikErrors } from 'formik'

import { CourierServiceDto } from '../DTO/CourierServiceDto'

interface Props {
    values: any
    errors: FormikErrors<CourierServiceDto>
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    }
}

export const CourierServiceForm: FC<Props> = ({
    values,
    errors,
    handleChange
}) => {
       

    return (
        <Grid container spacing={2}>
            
            <Grid item xs={9}>
            <TextField 
                    label="Código del Servicio" 
                    value={values.serviceCode} 
                    onChange={handleChange('serviceCode')} 
                    error={ !!errors.serviceCode }
                    helperText={ errors.serviceCode }
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                />
                <TextField 
                    label="Nombre del Servicio" 
                    value={values.serviceName} 
                    onChange={handleChange('serviceName')} 
                    error={ !!errors.serviceName }
                    helperText={ errors.serviceName }
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                />
            </Grid>   

            <Grid item xs={12}>
                <FormControlLabel control={<Switch checked={values.active} onChange={handleChange('active')} />} label='Activo'/>
            </Grid>
        </Grid>
    )
}