import React, { FC, useState  } from 'react'
//import PropTypes from 'prop-types';
//import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import {CourierServiceList} from '../../CourierService/CourierServiceList'
import {CourierParameterList} from '../../CourierParameter/CourierParameterList'
import {CourierDispatchStateList} from '../../CourierDispatchState/CourierDispatchStateList'

interface Props {
    tabValue: number,
    id : number
}


function TabContainer(props :any) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
  }


export const TabCourier: FC<Props> = ({
    tabValue,
    id
}) => {

    const [value, setValue] = useState(tabValue);
  
    const  handleChange = (event : any, value : any) => {
        console.log( event, value)
        setValue( value );
      };

    return (
        <div >
          <AppBar position="static">
            <Tabs value={value} 
                  onChange={handleChange}
                  variant="fullWidth"
                  centered
            >
              <Tab label="Servicios del Courier" />
              <Tab label="Estados del Courier" />
              <Tab label="Parámetros del Courier" />
            </Tabs>
          </AppBar>
          {value === 0 && <TabContainer><CourierServiceList courierId={id} ></CourierServiceList></TabContainer>}
          {value === 1 && <TabContainer><CourierDispatchStateList courierId={id} ></CourierDispatchStateList></TabContainer>}
          {value === 2 && <TabContainer><CourierParameterList courierId={id} ></CourierParameterList></TabContainer>}
        </div>
      );

}