
import React, { FC, ComponentType, Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import { AxiosConfig } from './utils/AxiosConfig'

import { configuration } from './configuration'

export interface App {
    name: string
    visibleName: string
    iconUrl: string
    url: string
    component: ComponentType
    submenus?: {
        name: string
        url: string
    }[]
}

export const configs = () => {
    const App: FC = () => {
      


        return (
            <Fragment>
                <AxiosConfig/>               
                    <Switch>

                        {
                            configuration.submenus?.map((submenu) => {
                                return submenu.items?.map(item => {
                                    return <Route key={submenu.name} path={`${configuration.url}/${item.url}`} component={item.component} />
                                })
                            })
                        }
                      
                    </Switch>               
            </Fragment>
        )
    }

    const config: App = {
        ...configuration,
        component: App,
    }

    return config
}