import * as yup from 'yup'

const nameYup = yup
    .string()
    .required('Este campo es requerido')

const posCodeYup = yup
    .string()
    .required('Este campo es requerido')

const storeCodeYup = yup
    .string()
    .required('Este campo es requerido')

export const posSchema = yup.object().shape({
    name: nameYup,
    posCode: posCodeYup,
    storeCode: storeCodeYup,
})

export const updatePosSchema = yup.object().shape({
    name: nameYup,
    posCode: posCodeYup,
    storeId: storeCodeYup,
})