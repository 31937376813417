import React, { FC } from 'react'
import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core'


import { FormikErrors } from 'formik'

import { ActionDispatchStateDto } from '../DTO/ActionDispatchStateDto'

interface Props {
    values: any
    errors: FormikErrors<ActionDispatchStateDto>
    disabled? : boolean
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    }
}

export const ActionDispatchStateForm: FC<Props> = ({
    values,
    errors,
    disabled,
    handleChange
}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <TextField 
                    label="Código" 
                    value={values.actionId} 
                    onChange={handleChange('actionId')} 
                    error={ !!errors.actionId }
                    helperText={ errors.actionId }  
                    disabled = {disabled}               
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 10 } }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={9}>
                <TextField 
                    label="Nombre de la acción" 
                    value={values.actionName} 
                    onChange={handleChange('actionName')} 
                    error={ !!errors.actionName }
                    helperText={ errors.actionName }
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                />
            </Grid>  
            <Grid item xs={12}>
                <TextField 
                    label="Descripción de la acción" 
                    value={values.description} 
                    onChange={handleChange('description')} 
                    error={ !!errors.description }
                    helperText={ errors.description }
                    inputProps={{ maxLength: 150 }}
                    fullWidth
                />
            </Grid>    

            <Grid item xs={12}>
                <FormControlLabel control={<Switch checked={values.active} onChange={handleChange('active')} />} label='Activo'/>
            </Grid>
        </Grid>
    )
}