import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
    createStyles({
        container: {
            padding: 18,
            border: '1px solid #E5E5E5',
            borderRadius: 1,
            background: '#F8F8F8',
            boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.15)'
        }
    })
)
