import React, { FC } from 'react'
import { createStyles, FormControlLabel, makeStyles, Switch } from '@material-ui/core'
import { useFormik } from 'formik'

export interface IFPayForm {
    active: boolean
}

export const initialValues: IFPayForm = {
    active: false,
}

interface Props extends ReturnType<typeof useFormik> {
    
}

export const FPayForm: FC<Props> = ({
    values,
    handleChange,
}) => {
    const styles = useStyles()

    return (
        <div className={styles.container}>
            <FormControlLabel
                label={values.active ? 'Activado' : 'Desactivado'}
                control={
                    <Switch checked={values.active} onChange={handleChange('active')}/>
                }
            />
        </div>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        input: {
            marginBottom: 20,
        }
    })
)