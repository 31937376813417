import React, { FC } from 'react'
import { makeStyles, createStyles, Button, TextField, Grid } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'

interface Props {
    storeCode: string
    onStoreCodeChange: (storeCode: string) => void
    storeName: string
    onStoreNameChange: (storeName: string) => void
    onSearch: () => void
}

export const StoreSearch: FC<Props> = ({
    storeCode,
    onStoreCodeChange,
    storeName,
    onStoreNameChange,
    onSearch
}) => {
    const styles = useStyles()
    return (
        <div className={styles.container}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                    <TextField label="Ingrese codigo tienda" value={storeCode} onChange={(event) => onStoreCodeChange(event.target.value as string)}/>
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                    <TextField label="Nombre Tienda" value={storeName} onChange={(event) => onStoreNameChange(event.target.value as string)}/>
                </Grid>
            </Grid>
            <Button variant='text' color='primary' endIcon={<SearchIcon/>} onClick={onSearch}>Buscar</Button>
        </div>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        }
    })
)