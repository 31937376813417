import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { StandardPage } from '../../components/_EOS/StandardPage'
import { State } from '../../components/_EOS/State'
import { useApi } from '../../hooks/useApi'
import { SellerDto } from './entities/SellerDto'
import { get } from './api'

import { EditForm } from './forms/EditForm'

interface Props {

}

export const SellerEdit: FC<Props> = () => {
    const { id } = useParams<{id: string}>()
    const { state, callApi } = useApi<SellerDto>()

    useEffect(() => {
        callApi(get(id))
    }, [id])
 
    return (
        <StandardPage pageTitle='Vendedor' backAction={true}>
            <State state={state} loadingMessage='Cargando Vendedor'>
                {
                    state.data && 
                        <EditForm seller={state.data} />
                }
            </State>
        </StandardPage>
    )
}