/* 
    Copyright 2022-06-23 por Diego Andres Estrada Holding S.A ©
    Detalle: Se agrega a EOS mantenedor de configuraciones internacionales POS
*/
import React, { FC } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { ConfigList } from './ConfigList'
interface Props {

}

export const InternationalizationPage: FC<Props> = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path} component={ConfigList}/>
        </Switch>
    )
}