import React, { FC } from 'react'
import {
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    makeStyles,
    createStyles,
    IconButton,
    TableContainer
} from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { CourierParameterDto } from './DTO/CourierParameterDto'
import { Pagination, PaginationProps } from '../../components/Pagination'


interface Props extends PaginationProps {
    title: string
    info: string
    data?: CourierParameterDto[]
    buttonText: string
    buttonEditAction?: (courierParameter: CourierParameterDto) => void
    buttonDeleteAction?: (id: number) => void
}

export const CourierParameterTable: FC<Props> = ({
    data = [],
    buttonEditAction,
    pagination,
    onPaginationChange,
    buttonDeleteAction
}) => {
    const styles = useStyles()


    return (

        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>Tipo</TableCell>
                        <TableCell align='center'>Parámetro</TableCell>
                        <TableCell align='center'>Valor</TableCell>
                        <TableCell align='center'>Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map(item => {
                            return (
                                <TableRow key={item.courierParameterId}>
                                    <TableCell align='center'>{item.type == 1 ? 'Conexión a la API' : item.type == 2 ? 'Configuración Request' : 'General'}</TableCell>
                                    <TableCell align='center'>{item.key}</TableCell>
                                    <TableCell align='center'>{item.value}</TableCell>
                                    <TableCell align='center'>
                                        <div className={styles.action} >
                                            <IconButton onClick={() => buttonEditAction && buttonEditAction(item)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton aria-label='delete' onClick={() => buttonDeleteAction && buttonDeleteAction(item.courierParameterId)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            <Pagination pagination={pagination} onPaginationChange={onPaginationChange} />
        </TableContainer>

    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            marginTop: 20,
            marginBottom: 20,
        },
        action: {
            display: 'inline-block',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        titleContainer: {
            flex: 1,
        },
        actionContainer: {
            flex: 0.25,
            display: 'flex',
            justifyContent: 'space-between'
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
        }
    })
)

