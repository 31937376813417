import React, { FC } from 'react'
import { createStyles, makeStyles, TextField } from '@material-ui/core'
import { FormikErrors } from 'formik'

interface Props {
    values: any,
    errors: FormikErrors<{
        dni: string,
        name: string,
        email: string
    }>
    onHandleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    }
}

export const CreateForm:FC<Props> = ({
    values,
    errors,
    onHandleChange
}) => {
    const styles = useStyles()

    return(
        <div className={styles.container}>
            <TextField 
                className={styles.input} 
                label="Rut" 
                value={values.dni} 
                onChange={onHandleChange('dni')}
                error={ errors.dni !== undefined }
                helperText={ errors.dni } 
            />
            <TextField 
                className={styles.input} 
                label="Nombre" 
                value={values.name} 
                onChange={onHandleChange('name')}
                error={ errors.name !== undefined }
                helperText={ errors.name } 
            />
            <TextField 
                className={styles.input} 
                label="Email" 
                value={values.email} 
                onChange={onHandleChange('email')}
                error={ errors.email !== undefined }
                helperText={ errors.email } 
            />
        </div>
    )
}



const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        input: {
            marginBottom: 20,
        }
    })
)
