import React, { FC } from 'react'
import {
    Button,
    Typography,
    makeStyles,
    createStyles
} from '@material-ui/core'

interface Props {
    title: string
    info: string
    buttonText: string
    buttonAction?: () => void
}

export const PaymentMethodItem: FC<Props> = ({
    title,
    info,
    buttonText,
    buttonAction
}) => {

    const styles = useStyles()

    return (
        <div className={`${styles.container} ${styles.row}`}>
            <div className={`${styles.titleContainer}`}>
                <Typography>{title}</Typography>
            </div>
            <div className={`${styles.actionContainer} ${styles.row}`}>
                <Typography>{info}</Typography>
                <Button variant='outlined' color='primary' onClick={buttonAction}>{buttonText}</Button>
            </div>
        </div>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            marginTop: 20,
            marginBottom: 20,
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        titleContainer: {
            flex: 1,
        },
        actionContainer: {
            flex: 0.25,
            display: 'flex',
            justifyContent: 'space-between'
        }
    })
)

