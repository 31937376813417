import { useState } from 'react'

export enum Status {
    Idle,
    Loading,
    Error,
    Success
}

export interface State<T = any> {
    status: Status
    data?: T
    error?: string
}

export const useFetchState = () => {
    const [state, setState] = useState<State>({ status: Status.Idle })

    const setIdle = () => {
        setState({ status: Status.Idle })
    }

    const setLoading = () => {
        setState({ status: Status.Loading })
    }

    const setError = (error?: string) => {
        setState({ status: Status.Error, error })
    }

    const setSuccess = (data?: any) => {
        setState({ status: Status.Success, data })
    }

    return {
        state,
        setIdle,
        setLoading,
        setError,
        setSuccess,
    }
}