/* 
    Copyright 2022-06-23 por Cristian Soto Ecomsur Holding S.A ©
    Detalle: Se agrega a EOS mantenedor de estados pedidos POS
*/

import * as yup from 'yup'


const codeYup = yup
    .string()
    .required('Este campo es requerido')

const nameYup = yup
    .string()
    .required('Este campo es requerido')

const descriptionYup = yup
    .string()
    .required('Este campo es requerido')

const sendEmailYup = yup
    .string()
    .required('Este campo es requerido')

export const sellerSchema = yup.object().shape({
    code: codeYup,
    name: nameYup,
    description: descriptionYup,
    sendEmail: sendEmailYup,
})