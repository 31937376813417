import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { StandardPage } from '../../components/_EOS/StandardPage'
import { State } from '../../components/_EOS/State'
import { useApi } from '../../hooks/useApi'
import { PosDto } from './entities/PosDto'
import { get, getPaymentMethodPos } from './api'

import { EditForm } from './forms/EditForm'
import { PaymentMethodPosDto } from './entities/PaymentMethodPosDto'
import { PaymentMethodPos } from './paymentMethodPos/PaymentMethodPos'

interface Props {

}

export const PosEdit:FC<Props> = () => {
    const { id } = useParams<{id: string}>()
    const posApi = useApi<PosDto>()
    const paymentMethodApi = useApi<PaymentMethodPosDto[]>()

    const fetchPaymentMethod = () => {
        if (posApi.state.data) {
            const { posCode } = posApi.state.data
            paymentMethodApi.callApi(getPaymentMethodPos(posCode))
        }
    }

    useEffect(() => {
        posApi.callApi(get(id))
    }, [id])

    useEffect(() => {
        fetchPaymentMethod()
    }, [posApi.state])
 
    return (
        <StandardPage pageTitle='Pos' backAction={true}>
            <State state={posApi.state} loadingMessage='Cargando Pos'>
                {
                    posApi.state.data && 
                        <EditForm pos={posApi.state.data} />
                }
            </State>
            {
                posApi.state.data &&
                <State state={paymentMethodApi.state} loadingMessage='Cargando Metodos de pago'>
                    <PaymentMethodPos posCode={posApi.state.data.posCode} paymentMethodPos={paymentMethodApi.state.data} onReset={fetchPaymentMethod}/>
                </State>
            }
        </StandardPage>
    )
}