import { useEffect } from 'react'
import axios, { AxiosResponse } from 'axios'

import { State, useFetchState } from './useFetchState'

export interface ReturnType<T> {
    state: State<T>
    callApi: (apiCall: Promise<AxiosResponse<any>>) => Promise<any>
}

export function useApi<T> () : ReturnType<T> {
    const { state, setLoading, setSuccess, setError } = useFetchState()

    useEffect(() => {
        const source = axios.CancelToken.source()

        const interceptorNumber = axios.interceptors.request.use((config) => {
            config.cancelToken = source.token
            return config
        })

        return () => {
            source.cancel("Llamada cancelada.")
            axios.interceptors.request.eject(interceptorNumber)
        }
    }, [])

    const callApi = (apiCall: Promise<AxiosResponse<any>>) => {
        setLoading()
        return new Promise((resolve, reject) => {
            apiCall
                .then(({ data }) => {
                    setSuccess(data)
                    resolve(data)
                })
                .catch((error) => {
                    console.log(error)
                    setError(error)
                    reject(error)
                })
        })
    }

    return {
        state,
        callApi
    }
}