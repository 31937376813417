import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { StandardPage } from '../../components/_EOS/StandardPage'
import { State } from '../../components/_EOS/State'
import { StoreTable } from './StoreTable'
import { useApi } from '../../hooks/useApi'
import { usePagination } from '../../hooks/usePagination'
import { StoreCreate } from './StoreCreate'
import { StoreSearch } from './StoreSearch'
import { ApiResponse } from '../../typings/ApiResponse'
import { StoreDto } from './entities/StoreDto'
import { getList } from './api'

interface Props {

}

export const StoreList: FC<Props> = () => {
    const history = useHistory()
    const { path } = useRouteMatch()
    const { state, callApi } = useApi<ApiResponse<StoreDto>>()
    const pagination = usePagination(5)
    const [createModalOpen, setCreateModalOpen] = useState(false)

    const [storeName, setStoreName] = useState('')
    const [storeCode, setStoreCode] = useState('')

    const fetchData = (code?: string, name?:string, onlyActive = false, page?: number, pageSize?: number) => {
        const pageParameter = page !== undefined ? page : pagination.page
        const pageSizeParameter = pageSize !== undefined ? pageSize : pagination.pageSize
        callApi(getList(code, name, onlyActive, pageParameter, pageSizeParameter))
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleActionButton = useCallback(() => {
        setCreateModalOpen(true)
    }, [])

    const handleEdit = (id: number) => {
        history.push(`${path}/edit/${id}`)
    }

    const handleModalClose = (success?: boolean) => {
        if (success) {
            fetchData()
            pagination.reset()
            setStoreName('')
            setStoreCode('')
        }
        setCreateModalOpen(false)
    }

    const handlePaginationChange = (page: number, pageSize: number) => {
        fetchData(storeCode, storeName, false, page, pageSize)
        pagination.setPagination(page, pageSize)
    }

    const handleSearch = () => {
        fetchData(storeCode, storeName, false, 0, 5)
        pagination.reset()
    }

    return (
        <Fragment>
            <StandardPage pageTitle='Tiendas' buttonTitle='Crear' buttonAction={handleActionButton}>
                <StoreSearch 
                    storeCode={storeCode}
                    onStoreCodeChange={setStoreCode}
                    storeName={storeName}
                    onStoreNameChange={setStoreName}
                    onSearch={handleSearch}/>
                <State state={state} loadingMessage='Cargando Tiendas'>
                    <StoreTable 
                        data={state.data?.items}
                        onEdit={handleEdit}
                        pagination={{
                            totalItem: state.data?.totalItems || 0,
                            page: pagination.page,
                            pageSize: pagination.pageSize,
                        }}
                        onPaginationChange={handlePaginationChange}
                    />
                </State>
            </StandardPage>
            <StoreCreate open={createModalOpen} onClose={handleModalClose} />
        </Fragment>
    )
}