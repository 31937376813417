import React, { FC } from 'react'
import { useFormik } from 'formik'
import { LoadingButton } from '../../../components/_EOS/Button/LoadingButton'

import { Status } from '../../../hooks/useFetchState'
import { DispatchStateDto } from '../entities/DispatchStateDto'
import { dispatchStateSchema } from './schema'
import { useApi } from '../../../hooks/useApi'
import { update } from '../api'
import { useNotification } from '../../../hooks/useNotification'
import { Container, makeStyles, createStyles } from '@material-ui/core'
import { DispatchStateForm } from './DispatchStateForm'
import { useHistory } from 'react-router-dom'
import { SnackBar } from '../../../components/Notification/Snackbar'

interface Props {
    dispatchState: DispatchStateDto
}

export const EditForm: FC<Props> = ({
    dispatchState
}) => {
    const styles = useStyles()
    const history = useHistory()
    const { state, callApi} = useApi()
    const notification = useNotification()

    const {
        values,
        errors,
        handleChange,
        submitForm
    } = useFormik<DispatchStateDto>({
        initialValues: dispatchState,
        onSubmit: (values: DispatchStateDto) => {
            sendData(values) 
        },
        validationSchema: dispatchStateSchema,
        validateOnChange: true
    })

    const sendData = (dispatchState: DispatchStateDto) => {
        callApi(update(dispatchState))
            .then(() => {
                notification.success("El registro se actualizó exitosamente")
                history.goBack()                
            })
            .catch(() => {
                notification.error("Ocurrio un error al actualizar el registro")
            })
    }

    const handleSubmitForm = () => {
        submitForm()
    }

    return (
        <Container className={styles.editContainer}>
            <DispatchStateForm values={values} errors={errors} handleChange={handleChange} />
            <div className={styles.buttonContainer}>
                <LoadingButton loading={state.status === Status.Loading} onClick={handleSubmitForm}>
                    Editar
                </LoadingButton>
            </div>
            <SnackBar
                open={notification.snackBarOpen}
                setOpen={notification.handleSnackbar}
                message={notification.snackBarMessage}
                status={notification.snackBarStatus}
            ></SnackBar>    
      
        </Container>
    )
}

const useStyles = makeStyles(
    createStyles({
        editContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: 10,
        },
        editInput: {
            marginBottom: 30,
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
        }
    })
)