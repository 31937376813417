import React, { FC, useEffect, useState } from 'react'
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { FormikErrors } from 'formik'
import { CourierDispatchStateDto } from '../DTO/CourierDispatchStateDto'
import { DispatchStateDto } from '../../DispatchStates/entities/DispatchStateDto'
import { DispatchStateTypeDto } from '../../DispatchStates/entities/DispatchStateTypeDto'

interface Props {
    values: any
    dispatchStateType?: DispatchStateTypeDto[]
    dispatchState?: DispatchStateDto[]
    errors: FormikErrors<CourierDispatchStateDto>
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
      
    }

}

export const CourierDispatchStateForm: FC<Props> = ({
    values,
    dispatchState,
    dispatchStateType,
    errors,
    handleChange
}) => {


    const [dispatchStatetypeId, SetDispatchStateTypeId] = useState(values.dispatchStateTypeId)

    const [dispatchStateArray, SetDispatchStateArray] = useState(dispatchState && dispatchState.filter(x => x.dispatchStateTypeId == dispatchStatetypeId))

    useEffect(() => {
        SetDispatchStateArray(dispatchState && dispatchState.filter(x => x.dispatchStateTypeId == dispatchStatetypeId))
    }, [dispatchStatetypeId])


    const handleSelectChange = (event: any) => {
        const value = event.target.value
        SetDispatchStateTypeId(value)
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <FormControl fullWidth error={!!errors.dispatchStateTypeId}>
                    <InputLabel id='labelStateTypeId'>Tipo Estado de ecomsur</InputLabel>
                    <Select
                        labelId='labelStateTypeId'
                        value={dispatchStatetypeId}
                        onChange={ e => { handleChange('dispatchStateTypeId'); handleSelectChange(e)}}
                    >
                        <MenuItem value='0'>Seleccione tipo estado ecomsur</MenuItem>
                        {
                            dispatchStateType && dispatchStateType.map(item => {
                                return (
                                    <MenuItem key={item.dispatchStateTypeId} value={item.dispatchStateTypeId}>{item.stateName} </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={9}>
                <FormControl fullWidth error={!!errors.dispatchStateTypeId}>
                    <InputLabel id='labelStateId'>Estado de ecomsur</InputLabel>
                    <Select
                        labelId='labelStateId'
                        value={values.dispatchStateId || ''}
                        onChange={handleChange('dispatchStateId')}
                    >
                        <MenuItem value=''>Seleccione estado ecomsur</MenuItem>
                        {
                            dispatchStateArray && dispatchStateArray.map(item => {
                                return (
                                    <MenuItem key={item.dispatchStateId} value={item.dispatchStateId}>{item.stateName} </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>


            <Grid item xs={9}>
                <TextField
                    label="Código del estado"
                    value={values.couierStateCode}
                    onChange={handleChange('couierStateCode')}
                    error={!!errors.couierStateCode}
                    helperText={errors.couierStateCode}
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={9}>
                <TextField
                    label="Descripción del estado"
                    value={values.couierState}
                    onChange={handleChange('couierState')}
                    error={!!errors.couierState}
                    helperText={errors.couierState}
                    inputProps={{ maxLength: 250 }}
                    fullWidth
                />
            </Grid>

        </Grid>
    )
}