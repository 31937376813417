import axios from 'axios'

import config from '../../config'

export const getStates = (pageIndex = 0, pageSize = 16) => {
    const url = new URL('states', config.apis.pos)
    url.searchParams.set('pageIndex', pageIndex.toString())
    url.searchParams.set('pageSize', pageSize.toString())
    return axios.get(url.toString())
}

export const getNeighborhoods = (stateCode: string, pageIndex = 0, pageSize = 99) => {
    const url = new URL('neighborhoods', config.apis.pos)
    url.searchParams.set('state', stateCode)
    url.searchParams.set('pageIndex', pageIndex.toString())
    url.searchParams.set('pageSize', pageSize.toString())
    return axios.get(url.toString())
}
