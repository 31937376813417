import React, { FC } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
    createStyles,
    IconButton,
    TableContainer
} from '@material-ui/core'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { ActionDispatchStateDto } from './DTO/ActionDispatchStateDto'
import { Pagination, PaginationProps } from '../../components/Pagination'

interface Props extends PaginationProps {
    data?: ActionDispatchStateDto[]
    onEdit?: (id: number) => void
    onDelete?: (id: number) => void
}

export const ActionDispatchStateTable: FC<Props> = ({
    data = [],
    onEdit,
    pagination,
    onPaginationChange,
    onDelete
}) => {
    const styles = useStyles()

  
    return (        
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>Código</TableCell>
                        <TableCell align='center'>Nombre</TableCell>
                        <TableCell align='center'>Descripción</TableCell>
                        <TableCell align='center'>Estado</TableCell>
                        <TableCell align='center'>Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map(item => {
                            return (
                                <TableRow key={item.actionId}>
                                    <TableCell align='center'>{item.actionId}</TableCell>
                                    <TableCell align='center'>{item.actionName}</TableCell>
                                    <TableCell align='center'>{item.description}</TableCell>
                                    <TableCell align='center'>{item.active ? 'Activo' : 'Inactivo'}</TableCell>
                                    <TableCell align='center'>
                                        <div className={styles.action}>
                                            <IconButton onClick={() => onEdit && onEdit(item.actionId)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton  aria-label='delete' onClick={() => onDelete && onDelete(item.actionId)}>                                                    
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            <Pagination pagination={pagination} onPaginationChange={onPaginationChange}/>
        </TableContainer>
    )
}

const useStyles = makeStyles(
    createStyles({
        action: {
            display: 'inline-block',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
        }
    })
)

