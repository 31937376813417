import React, { FC } from 'react'
import { makeStyles, createStyles, Button, IconButton, TextField, Grid } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'

interface Props {
    courierCode : string
    onCourierCodeChange : (courierCode: string) => void
    courierName: string
    onCourierNameChange: (courierName: string) => void
    onSearch: () => void
    onReset: () => void
}

export const CourierSearch: FC<Props> = ({
    courierCode,
    onCourierCodeChange,
    courierName,
    onCourierNameChange,
    onSearch,
    onReset
}) => {
    const styles = useStyles()
    return (
        <div className={styles.container}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                    <TextField label="Ingrese Código" 
                                value={courierCode} 
                                fullWidth
                                onChange={(event) => onCourierCodeChange(event.target.value as string)}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                    <TextField label="Ingrese Nombre" 
                               value={courierName}
                               onChange={(event) => onCourierNameChange(event.target.value as string)}
                    />
                </Grid>
            </Grid>
            <div className={styles.buttonContainer}>
                <IconButton color='primary' onClick={onReset}><RotateLeftIcon /></IconButton>
                <Button variant='text' color='primary' endIcon={<SearchIcon/>} onClick={onSearch}>Buscar</Button>
            </div>
        </div>
    )
}

const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
        }
    })
)