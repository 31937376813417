/* 
    Copyright 2022-06-23 por Diego Andres Estrada Holding S.A ©
    Detalle: Se agrega a EOS mantenedor de configuraciones internacionales POS
*/
import * as yup from 'yup'

const internacionalization = yup.object().shape({
    amountDecimals: yup.number(),
    taxRateAmount: yup.number()
})
export const configSchema = yup.object().shape({
    internacionalization
})