import axios from 'axios'

import config from '../../../config'
import { CourierCreateDto } from '../DTO/CourierCreateDto'
import { CourierDto } from '../DTO/CourierDto'


export const getList = (code?: string, name?: string, active = true, pageIndex = 0, pageSize = 10) => {
    const url = new URL('Courier', config.apis.mcim)
   
    if (code) {
        url.searchParams.set('courierCode', code)
    }
    if (name) {
        url.searchParams.set('name', name)
    }
    
    url.searchParams.set('pageIndex', pageIndex.toString())
    url.searchParams.set('pageSize', pageSize.toString())
    url.searchParams.set('active', active.toString())
    return axios.get(url.toString())
}

export const get = (id: string) => {
    const url = new URL(`Courier/${id}`, config.apis.mcim)
    return axios.get(url.toString())
}

export const create = (courier: CourierCreateDto) => {
    const url = new URL('Courier', config.apis.mcim)
    return axios.post(url.toString(), courier)
}

export const update = (courier: CourierDto) => {
    const url = new URL(`Courier/${courier.courierId}`, config.apis.mcim)
    return axios.put(url.toString(), courier)
}

export const remove = (id: string) => {
    const url = new URL(`Courier/${id}`, config.apis.mcim)
    return axios.delete(url.toString())
}

